export enum UserFlag {
    PmfSurveyResponded = 'pmfSurveyResponded',
    PmfSurveyDismissed = 'pmfSurveyDismissed',
    DownloadPromptAccepted = 'downloadPromptAccepted',
    DownloadPromptDismissed = 'downloadPromptDismissed',
    IphoneBetaPromptAccepted = 'iphoneBetaPromptAccepted',
    IphoneBetaPromptDismissed = 'iphoneBetaPromptDismissed',
    IphoneAppInstalled = 'iphoneAppInstalled',
    StreamingServiceSurveyDismissed = 'streamingServiceSurveyDismissed',
    StreamingServiceSurveySubmitted = 'streamingServiceSurveySubmitted',
    TipGiphyDismissed = 'tipGiphyDismissed',
    TipHelpDismissed = 'tipHelpDismissed',
    TipVideoChatDrag = 'tipVideoChatDrag',
    TipRoomAudioBalance = 'tipRoomAudioBalance',
    TipMobileWebAudioBalance = 'tipMobileWebAudioBalance',
    TipRoomFlair = 'tipRoomFlair',
}

export type UserFlags = { [key in UserFlag]: any };
