import {
    SignalRoomState,
    SignalParticipantUnregister,
    SignalParticipantUpdate,
    SignalParticipantsUpdate,
    SignalContentState,
    SignalContentStreamMetadata,
    SignalVoteStreakState,
    SignalVoteState,
    SignalParticipantMute,
    SignalRoomMutedUpdate,
    SignalRoomSettingsUpdate,
    SignalStageAccept,
    SignalStageInvite,
    SignalStageKick,
    SignalStageRSVP,
    SignalStageRequest,
    SignalStageUpdate,
    SignalQualityIndicator,
    SignalSubsQueueSizeUpdate,
} from '@internal/room-client/signal';
import { createAction } from '@reduxjs/toolkit';
import { RemoteStream } from '@playback-sports/ion-sdk-js/lib/stream';

export const onOpen = createAction('participant/onOpen');
export const onDisconnect = createAction('participant/onDisconnect');
export const onRoomState = createAction<SignalRoomState>('participant/onRoomState');
export const onRemoteStream = createAction<MediaStream>('participant/onRemoteStream');

export const onRemoteParticipantUnregister = createAction<SignalParticipantUnregister>(
    'participant/onRemoteParticipantUnregister'
);
export const onRemoteParticipantUpdate = createAction<SignalParticipantUpdate>(
    'participant/onRemoteParticipantUpdate'
);
export const onRemoteParticipantsUpdate = createAction<SignalParticipantsUpdate>(
    'participant/onRemoteParticipantsUpdate'
);
export const onContentState = createAction<SignalContentState>('participant/onContentState');
export const onContentStreamMetadata = createAction<SignalContentStreamMetadata>(
    'participant/onContentStreamMetadata'
);
export const onVoteStreakState = createAction<SignalVoteStreakState>(
    'participant/onVoteStreakState'
);
export const onVoteState = createAction<SignalVoteState>('participant/onVoteState');
export const onParticipantMute = createAction<SignalParticipantMute>(
    'participant/onParticipantMute'
);
export const onRoomMutedUpdate = createAction<SignalRoomMutedUpdate>(
    'participant/onRoomMutedUpdate'
);
export const onSubsQueueSizeUpdate = createAction<SignalSubsQueueSizeUpdate>(
    'participant/onSubsQueueSizeUpdate'
);
export const onRoomSettingsUpdate = createAction<SignalRoomSettingsUpdate>(
    'participant/onRoomSettingsUpdate'
);
export const onStageAccept = createAction<SignalStageAccept>('participant/onStageAccept');
export const onStageInvite = createAction<SignalStageInvite>('participant/onStageInvite');
export const onStageKick = createAction<SignalStageKick>('participant/onStageKick');
export const onStageRSVP = createAction<SignalStageRSVP>('participant/onStageRSVP');
export const onStageRequest = createAction<SignalStageRequest>('participant/onStageRequest');
export const onStageUpdate = createAction<SignalStageUpdate>('participant/onStageUpdate');
export const onQualityIndicator = createAction<SignalQualityIndicator>(
    'participant/onQualityIndicator'
);
