import { NotificationType } from '@internal/state/types';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { showNotification } from '../../state/app';

const GLOBALS = [
    NotificationType.MagicEmail,
    NotificationType.CopiedLink,
    NotificationType.TvAuthConnected,
    NotificationType.TvAuthVerified,
    NotificationType.TvAuthAccessDenied,
    NotificationType.TvAuthParseFailed,
    NotificationType.TvAuthStreamAccessRevoked,
    NotificationType.TvAuthPortalPending,
    NotificationType.TvAuthPlatformError,
];

const middleware =
    (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: AnyAction) => {
        if (
            action.type === showNotification.toString() &&
            !store.getState().room.joined &&
            !GLOBALS.includes(action.payload.type)
        ) {
            return;
        }

        return next(action);
    };

export default middleware;
