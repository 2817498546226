import { User, PermissionType } from '@internal/api/types';
import { Participant } from '@internal/room-client';

export interface RoomUser extends User {
    flair?: string;
}

export interface RoomParticipant extends Participant {
    flair?: string;
}

export enum RoomStates {
    Loading,
    LoadingRoom,
    Connecting,
    Reconnecting,
    VideoPending,
    VideoLoading,
    SubscribeError,
    RoomNotFound,
    OpenInBrowser,
    Disconnected,
    Error,
    Queued,
}

export type RoomPermissions = {
    [key in PermissionType]: boolean;
};
