import 'lib/polyfills';
import '../styles/global.css';
import '@reach/dialog/styles.css';
import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { wrapper } from 'state/store';
import { AppComponent, ModalType } from 'typescript/typings';
import usePageView from '../features/tracking/usePageView';
import dynamic from 'next/dynamic';
import useFirstUserInteraction from '../hooks/useFirstUserInteraction';
import { ignoreWhitelistedErrors } from 'utils/errors';
import AuthProvider from '../features/auth/context';
import AppLoader from '../components/AppLoader';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useRouter } from 'next/router';
import { setNavigating } from 'state/app';
import redirects from '../routing/redirects';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import { track } from 'state/app';
import { hideAccountPanel } from '@internal/state/accountPanel';
import { OVERLAY_ROOT_ID } from '../components/OverlayPortal';
import useIdle from '../hooks/useIdle';
import { QueryClient, QueryClientProvider } from 'react-query';
import { theme } from 'styles/theme';

const DynamicAuth = dynamic(() => import('features/auth/Auth'), { ssr: false });

interface IApp extends AppProps {
    Component: AppComponent<any>;
    pageProps: any;
}

const queryClient = new QueryClient();

const PlaybackApp: React.FunctionComponent<IApp> = ({ Component, pageProps, ...rest }) => {
    const Layout = Component.Layout || React.Fragment;
    const router = useRouter();

    const dispatch = useAppDispatch();
    const loaderVisible = useAppSelector(
        (state) => state.app.loaderVisible || state.app.navigating
    );
    const modalType = useAppSelector((state) => state.app.modal?.type);
    const loggedIn = useAppSelector((state) => !!state.user.id);
    const authRedirect = redirects[router.pathname];

    const [showAuth, setShowAuth] = useState(false);

    useIdle();
    usePageView();
    useFirstUserInteraction();

    useEffect(() => {
        ignoreWhitelistedErrors();

        const handleRouteChangeStart = () => {
            dispatch(hideAccountPanel());
        };

        const handleRouteChangeComplete = () => {
            dispatch(setNavigating(false));
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [dispatch, router]);

    useEffect(() => {
        // Don't unmount auth when closed
        if (modalType === ModalType.Login) {
            setShowAuth(true);
        }
    }, [modalType]);

    useEffect(() => {
        const handleUnload = () => {
            dispatch(track({ event: 'Close Window' }));
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=1, viewport-fit=cover"
                />
                <meta
                    name="facebook-domain-verification"
                    content="3zw4n8y0j5vammwk0sr30vefetni9l"
                />
            </Head>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={async (error) => {
                    dispatch(
                        track({
                            event: 'Error Caught at Boundary',
                            errorMessage: error.message,
                            errorName: error.name,
                            errorStack: error.stack,
                        })
                    );

                    const Sentry = await import('@sentry/nextjs');
                    Sentry.captureException(error, {
                        tags: {
                            appContext: 'errorBoundary',
                        },
                    });
                }}
            >
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <Layout>
                            <Component {...pageProps} {...rest} />
                            {showAuth && (
                                <DynamicAuth
                                    redirectOnLogin={!!authRedirect?.to && authRedirect?.loggedIn}
                                />
                            )}
                            <AppLoader
                                visible={loaderVisible}
                                transitionDuration={200}
                                transitionDelay={0}
                            />
                            <div id={OVERLAY_ROOT_ID} />
                        </Layout>
                    </QueryClientProvider>
                </AuthProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default wrapper.withRedux(PlaybackApp);
