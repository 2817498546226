export enum RoomEntryType {
    CreateRoom = 'create',
    DeepLink = 'deep link',
    RoomList = 'room list',
    Direct = 'direct',
    Notification = 'notification',
    Paste = 'paste',
    DupeDisconnect = 'dupe disconnect',
}

export enum ShadowEventType {
    Identify = 'i',
    Track = 't',
    Page = 'p',
}
