import axios from 'axios';
import { isProduction } from './env';

export const getData = async <T>(url: string, token?: string) => {
    try {
        const response = await axios.get<T>(url, { headers: getHeaders(token) });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const postData = async <T>(url: string, payload: any, token?: string) => {
    try {
        const response = await axios.post<T>(url, payload, { headers: getHeaders(token) });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getHeaders = (token?: string) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
};

export const getGcsRoomImageBasePath = () => {
    return isProduction() ? `room-thumbnails/` : `room-thumbnails-dev/`;
};

export const getGcsRoomImagePath = (urlName: string) => {
    return `${getGcsRoomImageBasePath()}${urlName}.png`;
};
