import { createAction } from '@reduxjs/toolkit';
import { TrackingContext } from '../../typescript/typings';
import { AppState } from './appSlice';

export const init =
    createAction<
        Pick<
            AppState,
            | 'mobile'
            | 'osName'
            | 'osVersion'
            | 'browserName'
            | 'browserVersion'
            | 'deviceType'
            | 'deviceVendor'
            | 'deviceModel'
        >
    >('app/init');

interface TrackingProperties {
    event: string;
    context?: TrackingContext;
    [key: string]: any;
}

export const track = createAction<TrackingProperties>('app/track');

export const pageView = createAction<{ title: string } & any>('app/pageView');

export const identifyUser = createAction<any>('app/identifyUser');
