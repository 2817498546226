import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const customNanoid = customAlphabet(alphabet, 10);

const nanoid = (): string => {
    return customNanoid();
};

export default nanoid;
