import axios, { AxiosError } from 'axios';
import { AddonTypes, Platforms, SubscriptionTypes, TVAuthStatus } from '@internal/tv-auth';
import { TVAuthAttempt, TVAuthSubscription } from './types';

export type RequestTVSubscriptionsGET = {
    token: string;
};

export const tvAuthSubscriptionsGET = async (
    token: string,
    origin: string
): Promise<TVAuthSubscription[]> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/tv-auth/subscriptions`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
    }).catch((e: AxiosError) => {
        throw e;
    });

    return resp.data;
};

export type RequestTVAuthAttemptPUT = {
    platform: Platforms;
    username: string;
    location: string;
    ipAddress: string;
    pageID: string;
    connectionID: string;
};

export const tvAuthAttemptPUT = async (
    token: string,
    request: RequestTVAuthAttemptPUT,
    origin: string
): Promise<TVAuthAttempt> => {
    const resp = await axios({
        method: 'PUT',
        url: `${origin}/tv-auth/attempt`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestTVAuthAttemptPOST = {
    id: string;
    status: TVAuthStatus;
};

export const tvAuthAttemptPOST = async (
    token: string,
    request: RequestTVAuthAttemptPOST,
    origin: string
): Promise<void> => {
    await axios({
        method: 'POST',
        url: `${origin}/tv-auth/attempt`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export type RequestTVAuthSubscriptionPUT = {
    attemptID: string;
    platform: Platforms;
    username: string;
    location: string;
    subscription: SubscriptionTypes;
    addons: AddonTypes[];
};

export const tvAuthSubscriptionPUT = async (
    token: string,
    request: RequestTVAuthSubscriptionPUT,
    origin: string
): Promise<TVAuthSubscription> => {
    const resp = await axios({
        method: 'PUT',
        url: `${origin}/tv-auth/subscription`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestTVAuthSubscriptionPOST = {
    id: string;
    location: string;
    subscription: SubscriptionTypes;
    addons: AddonTypes[];
    markDeleted: boolean;
};

export const tvAuthSubscriptionPOST = async (
    token: string,
    request: RequestTVAuthSubscriptionPOST,
    origin: string
): Promise<TVAuthSubscription> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/tv-auth/subscription`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestTVAuthSubscriptionDELETE = {
    platform: Platforms;
};

export const tvAuthSubscriptionDELETE = async (
    token: string,
    request: RequestTVAuthSubscriptionDELETE,
    origin: string
): Promise<TVAuthSubscription> => {
    const resp = await axios({
        method: 'DELETE',
        url: `${origin}/tv-auth/subscription`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};
