import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setRemoteStream } from './sharedActions';

export interface ContentState {
    remoteStream: MediaStream;
    subscribeError: boolean;
    muted: boolean;
    volume: number;
}

export const initialState: ContentState = {
    remoteStream: null,
    subscribeError: false,
    muted: false,
    volume: 50,
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        removeRemoteStream(state) {
            state.remoteStream = null;
        },
        changeVolume(state, action: PayloadAction<number>) {
            state.muted = action.payload === 0;
            state.volume = action.payload;
        },
        toggleContentMuted(state, action: PayloadAction<boolean>) {
            const nextMuted = typeof action.payload === 'undefined' ? !state.muted : action.payload;
            state.muted = nextMuted;

            if (!nextMuted && state.volume === 0) {
                state.volume = 50;
            }
        },
        setSubscribeError(state, action: PayloadAction<boolean>) {
            state.subscribeError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRemoteStream, (state, action) => {
            const stream = action.payload;
            state.remoteStream = stream;
        });
    },
});

export const { removeRemoteStream, changeVolume, toggleContentMuted, setSubscribeError } =
    contentSlice.actions;

export * from './sharedActions';

export default contentSlice.reducer;
