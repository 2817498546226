import { postData } from 'utils/api';
import packageJson from '../../package.json';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import Router from 'next/router';
import { v4 } from 'uuid';
import { ReferralContext, REFERRAL_CONTEXT_NAME } from 'typescript/typings';
import { ShadowEventType, RoomEntryType } from '@internal/tracking/types';
import { track, pageView, identifyUser, trackingQueuePush } from 'state/app';
import { isProduction } from '../../utils/env';
import { LocalStorageKey, localStorageGet, localStorageSet } from '../local-storage/storage';
import { AppState } from '../../state/store';
import { RoomSettings, TVAuthSubscription } from '@internal/api/types';
import { SubscriptionTypes } from '@internal/tv-auth';
import { subTypesToNames } from '@internal/tv-auth/platforms';

const canTrack = () => {
    if (!isProduction() || typeof window === 'undefined') {
        return false;
    }

    const url = new URL(window.location.href);
    return !url.searchParams.has('recorder') && !url.searchParams.has('dnt');
};

const getAnalyticsContext = (app: AppState['app']) => {
    return {
        active: true,
        app: {
            name: 'web',
            version: packageJson.version,
        },
        device: {
            type: app.deviceType,
            model: app.deviceModel,
            manufacturer: app.deviceVendor,
        },
        locale: navigator.language,
        os: {
            name: app.osName,
            version: app.osVersion,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
};

export const getAnonymousID = () => {
    let uid = localStorageGet(LocalStorageKey.Uid);

    if (!uid) {
        uid = v4();
        localStorageSet(LocalStorageKey.Uid, uid);
    }

    return uid;
};

const getRoomType = (settings?: RoomSettings) => {
    if (typeof settings?.openSpeak !== 'boolean') {
        return null;
    }

    return settings.openSpeak ? 'open' : 'stage';
};

const getTvSubscriptions = (subscriptions: TVAuthSubscription[]) => {
    return subscriptions
        .filter((subscription) => subscription.subscription !== SubscriptionTypes.None)
        .map((subscription) => subTypesToNames[subscription.subscription]);
};

const tracking =
    (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: AnyAction) => {
        const state = store.getState() as AppState;

        const getBaseProperties = () => {
            return {
                roomID: state.room.urlName,
                roomName: state.room.name,
                roomEntryType:
                    state.app.roomEntryType === null
                        ? RoomEntryType.Direct
                        : state.app.roomEntryType,
                roomType: getRoomType(state.room.settings),
                name: state.user.name,
                userID: state.user.id,
                tvSubscriptions: getTvSubscriptions(state.user.tvSubscriptions),
                camera: state.chat.camera.enabled && state.chat.camera.permission,
                mic: state.chat.mic.enabled && state.chat.mic.permission,
                participantID: state.room.participantID,
                participantCount: state.room.participants.allIds.length,
                userCount: Object.keys(state.room.userParticipants).length,
                roomSize: state.room.members.allIds.length,
                version: packageJson.version,
                platform: 'web',
                osName: state.app.osName,
                osVersion: state.app.osVersion,
                browserName: state.app.browserName,
                browserVersion: state.app.browserVersion,
                streamerID: state.room.contentStreamMetadataState?.userID,
                chatOpen: state.messages.open,
                popoutChat: state.messages.popout,
                mobile: state.app.mobile,
                page: {
                    path: window.location.pathname,
                    url: window.location.href,
                    title: document.title,
                    search: window.location.search,
                    referrer: document.referrer,
                },
            };
        };

        const getUserIds = () => {
            return {
                userId: state.user.id,
                anonymousId: getAnonymousID(),
            };
        };

        const getUserTraits = () => {
            const { email, name, avatar } = state.user;

            const traits: any = {
                email,
                name,
                avatar: !!avatar?.url,
            };

            return traits;
        };

        const queued = () => {
            if (!state.user.initialized) {
                store.dispatch(trackingQueuePush(action));
                return true;
            }

            return false;
        };

        switch (action.type) {
            case identifyUser.toString(): {
                if (queued()) break;

                const data = action.payload || {};
                const payload = {
                    type: ShadowEventType.Identify,
                    traits: {
                        ...getUserTraits(),
                        ...data,
                    },
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.group(
                        `%cIdentify: %c${payload.userId}`,
                        'color: #DB3236; font-weight: bold',
                        'color: #4EA866; font-weight: bold'
                    );
                    Object.keys(payload.traits).forEach((key) => {
                        console.log(`%c${key}`, 'font-weight: bold', payload.traits[key]);
                    });
                    console.groupEnd();
                }

                break;
            }
            case track.toString(): {
                if (queued()) break;

                const { event, ...data } = action.payload;
                const augmentedData = {
                    ...getBaseProperties(),
                    ...data,
                };

                const payload = {
                    type: ShadowEventType.Track,
                    event,
                    properties: augmentedData,
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.group(
                        `%cTrack: %c${event}`,
                        'color: #2B7BEC; font-weight: bold',
                        'color: #4EA866; font-weight: bold'
                    );
                    Object.keys(data).forEach((key) => {
                        console.log(`%c${key}`, 'font-weight: bold', data[key]);
                    });
                    console.groupEnd();
                }

                break;
            }
            case pageView.toString(): {
                if (queued()) break;

                const { title, ...properties } = action.payload;
                const { p } = Router.query;

                const augmentedData = {
                    ...getBaseProperties(),
                    ...properties,
                    referralLink:
                        typeof p !== 'undefined'
                            ? REFERRAL_CONTEXT_NAME[<ReferralContext>parseInt(<string>p)]
                            : null,
                };

                const payload = {
                    type: ShadowEventType.Page,
                    name: title || document.title,
                    properties: augmentedData,
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.group(
                        `%cPageview: %c${payload.name}`,
                        'color: #F6774F; font-weight: bold',
                        'color: #4EA866; font-weight: bold'
                    );
                    Object.keys(properties).forEach((key) => {
                        console.log(`%c${key}`, 'font-weight: bold', properties[key]);
                    });
                    console.groupEnd();
                }

                break;
            }
            default:
        }

        return next(action);
    };

export default tracking;
