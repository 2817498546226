export type MediaInputDevice = {
    label: string;
    deviceId: string;
    groupId: string;
    permission: boolean;
    enabled: boolean;
};

export enum ChatVisibilityMode {
    All,
    ActiveSpeaker,
    None,
}
