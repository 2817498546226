import { PagePermissions, RedirectOptions } from '../typescript/typings';

const redirects: { [path: string]: RedirectOptions } = {
    '/': { loggedIn: true, to: '/home' },
    '/invite': { loggedIn: true, to: '/home' },
    '/home': { loggedIn: false, to: '/' },
    '/mod/*': { permissions: [PagePermissions.Staff], loggedIn: false, to: '/' },
    '/api/mod/*': { permissions: [PagePermissions.Staff], loggedIn: false, to: '/' },
};

export const getRedirect = (path: string): RedirectOptions => {
    let basePath = path;
    if (path.endsWith('/mobile')) {
        basePath = path.split('/mobile')[0] || '/';
    }

    const match = Object.keys(redirects).find((p) => {
        if (p === basePath) {
            return true;
        }

        if (p.endsWith('*')) {
            const [prefix, _] = p.split('/*');
            if (basePath === prefix || basePath.startsWith(`${prefix}/`)) {
                return true;
            }
        }

        return false;
    });

    return match ? redirects[match] : null;
};

export default redirects;
