export enum Platforms {
    YouTubeTV = 1,
    NBALeaguePass = 2,
    SlingTV = 3,
    Xfinity = 4,
    FuboTV = 5,
    Optimum = 6,
    Spectrum = 7,
    DIRECTV = 8,
    Cox = 9,
    Hulu = 10,
    MLBTV = 11,
    Whitelist = 12,
    Verizon = 13,
    WNBALeaguePass = 14,
    Uverse = 15,
    Peacock = 16,
}

export const intlPlatforms = new Set([
    Platforms.NBALeaguePass,
    Platforms.WNBALeaguePass,
    Platforms.MLBTV,
]);

export const platformsToNames: { [key in Platforms]: string } = {
    [Platforms.YouTubeTV]: 'YouTube TV',
    [Platforms.NBALeaguePass]: 'NBA League Pass',
    [Platforms.SlingTV]: 'Sling TV',
    [Platforms.Xfinity]: 'Xfinity',
    [Platforms.FuboTV]: 'FuboTV',
    [Platforms.Optimum]: 'Optimum',
    [Platforms.Spectrum]: 'Spectrum',
    [Platforms.DIRECTV]: 'DIRECTV',
    [Platforms.Cox]: 'Cox',
    [Platforms.Hulu]: 'Hulu',
    [Platforms.MLBTV]: 'MLB.TV',
    [Platforms.Whitelist]: 'Whitelist',
    [Platforms.Verizon]: 'Verizon',
    [Platforms.WNBALeaguePass]: 'WNBA League Pass',
    [Platforms.Uverse]: 'ATT U-verse',
    [Platforms.Peacock]: 'Peacock',
};

export const namesToPlatforms = {
    [platformsToNames[Platforms.YouTubeTV]]: Platforms.YouTubeTV,
    [platformsToNames[Platforms.NBALeaguePass]]: Platforms.NBALeaguePass,
    [platformsToNames[Platforms.SlingTV]]: Platforms.SlingTV,
    [platformsToNames[Platforms.Xfinity]]: Platforms.Xfinity,
    [platformsToNames[Platforms.FuboTV]]: Platforms.FuboTV,
    [platformsToNames[Platforms.Optimum]]: Platforms.Optimum,
    [platformsToNames[Platforms.Spectrum]]: Platforms.Spectrum,
    [platformsToNames[Platforms.DIRECTV]]: Platforms.DIRECTV,
    [platformsToNames[Platforms.Cox]]: Platforms.Cox,
    [platformsToNames[Platforms.Hulu]]: Platforms.Hulu,
    [platformsToNames[Platforms.MLBTV]]: Platforms.MLBTV,
    [platformsToNames[Platforms.Verizon]]: Platforms.Verizon,
    [platformsToNames[Platforms.WNBALeaguePass]]: Platforms.WNBALeaguePass,
    [platformsToNames[Platforms.Uverse]]: Platforms.Uverse,
    [platformsToNames[Platforms.Peacock]]: Platforms.Peacock,
};

export enum SubscriptionTypes {
    None = 1,
    YouTubeTVBase = 2,
    FuboTVStarter = 3,
    FuboTVPro = 4,
    FuboTVElite = 5,
    FuboTVLatino = 6,
    SlingTVOrange = 7,
    SlingTVBlue = 8,
    SlingTVOrangeAndBlue = 9,
    DIRECTVSelect = 10,
    DIRECTVChoice = 11,
    DIRECTVEntertainment = 12,
    DIRECTVXtra = 13,
    DIRECTVUltimate = 14,
    DIRECTVPremier = 15,
    DIRECTVStreamEntertainment = 16,
    DIRECTVStreamChoice = 17,
    DIRECTVStreamUltimate = 18,
    DIRECTVStreamPremier = 19,
    NBALeaguePass = 20,
    NBALeaguePassPremium = 21,
    NBALeagueTeamPass = 22,
    NBALeaguePassIntl = 23,
    NBALeaguePassPremiumIntl = 24,
    NBALeagueTeamPassIntl = 25,
    CoxStarter = 26,
    CoxPreferred = 27,
    CoxUltimate = 28,
    XfinityDigitalStarter = 29,
    XfinityDigitalPreferred = 30,
    XfinityDigitalPremier = 31,
    SpectrumSelect = 32,
    SpectrumSilver = 33,
    SpectrumGold = 34,
    OptimumBroadcastBasic = 35,
    OptimumCore = 36,
    OptimumValue = 37,
    OptimumSelect = 38,
    OptimumPremier = 39,
    OptimumEconomy = 40,
    Failure = 41,
    XfinityX1Platform = 42,
    Pending = 43,
    AuthFailure = 44,
    Hulu = 45,
    HuluNoAds = 46,
    HuluDisneyESPN = 47,
    HuluDisneyNoAds = 48,
    HuluLiveTVDisneyESPN = 49,
    HuluLiveTVDisneyESPNNoAds = 50,
    MLBTVYearly = 51,
    MLBTVMonthly = 52,
    MLBTVSingleTeam = 53,
    Whitelist = 54,
    SpectrumStandardTV = 55,
    NBALeaguePassNBATV = 56,
    NBALeaguePassNBATVIntl = 57,
    SpectrumChoice = 58,
    YouTubeTVFreeTrial = 59,
    NBALeaguePassIndia = 60,
    VerizonFiosTestDrive = 61,
    VerizonYourFios = 62,
    VerizonMoreFios = 63,
    VerizonMostFios = 64,
    VerizonPreferredHD = 65,
    VerizonExtremeHD = 66,
    VerizonUltimateHD = 67,
    VerizonCustomTV = 68,
    MLBTVTMobile = 69,
    VerizonDoublePlay = 70,
    VerizonTriplePlay = 71,
    VerizonTVHD = 72,
    WNBALeaguePass = 73,
    DIRECTVPlus = 74,
    DIRECTVMax = 75,
    DIRECTVStreamPlus = 76,
    DIRECTVStreamMax = 77,
    CoxContourTV = 78,
    U450 = 79,
    U400 = 80,
    U300 = 81,
    U200 = 82,
    U100 = 83,
    Ufamily = 84,
    Ubasic = 85,
    Uverse = 86,
    DIRECTV = 87,
    DIRECTVStream = 88,
    DIRECTVGoBig = 89,
    DIRECTVStreamGoBig = 90,
    DIRECTVLiveALittle = 91,
    DIRECTVStreamLiveALittle = 92,
    DIRECTVJustRight = 93,
    DIRECTVStreamJustRight = 94,
    DIRECTVGottaHaveIt = 95,
    DIRECTVStreamGottaHaveIt = 96,
    HuluNoAdsLiveTV = 97,
    SpectrumTV = 98,
    MLBTVComplimentary = 99,
    MLBTVInAppPurchase = 100,
    PeacockFree = 101,
    PeacockPremium = 102,
    PeacockPremiumPlus = 103,
}

export const platformsToSubTypes: { [key in Platforms]: SubscriptionTypes[] } = {
    [Platforms.YouTubeTV]: [SubscriptionTypes.YouTubeTVBase, SubscriptionTypes.YouTubeTVFreeTrial],
    [Platforms.NBALeaguePass]: [
        SubscriptionTypes.NBALeaguePass,
        SubscriptionTypes.NBALeaguePassPremium,
        SubscriptionTypes.NBALeagueTeamPass,
        SubscriptionTypes.NBALeaguePassIntl,
        SubscriptionTypes.NBALeaguePassPremiumIntl,
        SubscriptionTypes.NBALeagueTeamPassIntl,
        SubscriptionTypes.NBALeaguePassIndia,
    ],
    [Platforms.SlingTV]: [
        SubscriptionTypes.SlingTVOrange,
        SubscriptionTypes.SlingTVBlue,
        SubscriptionTypes.SlingTVOrangeAndBlue,
    ],
    [Platforms.Xfinity]: [
        SubscriptionTypes.XfinityDigitalStarter,
        SubscriptionTypes.XfinityDigitalPreferred,
        SubscriptionTypes.XfinityDigitalPremier,
        SubscriptionTypes.XfinityX1Platform,
    ],
    [Platforms.FuboTV]: [
        SubscriptionTypes.FuboTVStarter,
        SubscriptionTypes.FuboTVPro,
        SubscriptionTypes.FuboTVElite,
        SubscriptionTypes.FuboTVLatino,
    ],
    [Platforms.Optimum]: [
        SubscriptionTypes.OptimumBroadcastBasic,
        SubscriptionTypes.OptimumCore,
        SubscriptionTypes.OptimumValue,
        SubscriptionTypes.OptimumSelect,
        SubscriptionTypes.OptimumPremier,
        SubscriptionTypes.OptimumEconomy,
    ],
    [Platforms.Spectrum]: [
        SubscriptionTypes.SpectrumSelect,
        SubscriptionTypes.SpectrumSilver,
        SubscriptionTypes.SpectrumGold,
        SubscriptionTypes.SpectrumStandardTV,
        SubscriptionTypes.SpectrumChoice,
        SubscriptionTypes.SpectrumTV,
    ],
    [Platforms.DIRECTV]: [
        SubscriptionTypes.DIRECTVSelect,
        SubscriptionTypes.DIRECTVChoice,
        SubscriptionTypes.DIRECTVEntertainment,
        SubscriptionTypes.DIRECTVXtra,
        SubscriptionTypes.DIRECTVUltimate,
        SubscriptionTypes.DIRECTVPremier,
        SubscriptionTypes.DIRECTVStreamEntertainment,
        SubscriptionTypes.DIRECTVStreamChoice,
        SubscriptionTypes.DIRECTVStreamUltimate,
        SubscriptionTypes.DIRECTVStreamPremier,
        SubscriptionTypes.DIRECTVPlus,
        SubscriptionTypes.DIRECTVMax,
        SubscriptionTypes.DIRECTVStreamPlus,
        SubscriptionTypes.DIRECTVStreamMax,
        SubscriptionTypes.DIRECTV,
        SubscriptionTypes.DIRECTVStream,
        SubscriptionTypes.DIRECTVGoBig,
        SubscriptionTypes.DIRECTVStreamGoBig,
        SubscriptionTypes.DIRECTVLiveALittle,
        SubscriptionTypes.DIRECTVStreamLiveALittle,
        SubscriptionTypes.DIRECTVJustRight,
        SubscriptionTypes.DIRECTVStreamJustRight,
        SubscriptionTypes.DIRECTVGottaHaveIt,
        SubscriptionTypes.DIRECTVStreamGottaHaveIt,
    ],
    [Platforms.Cox]: [
        SubscriptionTypes.CoxStarter,
        SubscriptionTypes.CoxPreferred,
        SubscriptionTypes.CoxUltimate,
        SubscriptionTypes.CoxContourTV,
    ],
    [Platforms.Hulu]: [
        SubscriptionTypes.Hulu,
        SubscriptionTypes.HuluNoAds,
        SubscriptionTypes.HuluNoAdsLiveTV,
        SubscriptionTypes.HuluDisneyESPN,
        SubscriptionTypes.HuluDisneyNoAds,
        SubscriptionTypes.HuluLiveTVDisneyESPN,
        SubscriptionTypes.HuluLiveTVDisneyESPNNoAds,
    ],
    [Platforms.MLBTV]: [
        SubscriptionTypes.MLBTVYearly,
        SubscriptionTypes.MLBTVMonthly,
        SubscriptionTypes.MLBTVSingleTeam,
        SubscriptionTypes.MLBTVTMobile,
        SubscriptionTypes.MLBTVComplimentary,
        SubscriptionTypes.MLBTVInAppPurchase,
    ],
    [Platforms.Whitelist]: [SubscriptionTypes.Whitelist],
    [Platforms.Verizon]: [
        SubscriptionTypes.VerizonFiosTestDrive,
        SubscriptionTypes.VerizonYourFios,
        SubscriptionTypes.VerizonMoreFios,
        SubscriptionTypes.VerizonMostFios,
        SubscriptionTypes.VerizonPreferredHD,
        SubscriptionTypes.VerizonExtremeHD,
        SubscriptionTypes.VerizonUltimateHD,
        SubscriptionTypes.VerizonCustomTV,
        SubscriptionTypes.VerizonDoublePlay,
        SubscriptionTypes.VerizonTriplePlay,
        SubscriptionTypes.VerizonTVHD,
    ],
    [Platforms.WNBALeaguePass]: [SubscriptionTypes.WNBALeaguePass],
    [Platforms.Uverse]: [
        SubscriptionTypes.U450,
        SubscriptionTypes.U400,
        SubscriptionTypes.U300,
        SubscriptionTypes.U200,
        SubscriptionTypes.Ufamily,
        SubscriptionTypes.Ubasic,
        SubscriptionTypes.Uverse,
    ],
    [Platforms.Peacock]: [
        SubscriptionTypes.PeacockFree,
        SubscriptionTypes.PeacockPremium,
        SubscriptionTypes.PeacockPremiumPlus,
    ],
};

export const subTypesToNames: Partial<{ [key in SubscriptionTypes]: string }> = {
    [SubscriptionTypes.None]: 'None',
    [SubscriptionTypes.YouTubeTVBase]: 'YouTubeTVBase',
    [SubscriptionTypes.FuboTVStarter]: 'FuboTVStarter',
    [SubscriptionTypes.FuboTVPro]: 'FuboTVPro',
    [SubscriptionTypes.FuboTVElite]: 'FuboTVElite',
    [SubscriptionTypes.FuboTVLatino]: 'FuboTVLatino',
    [SubscriptionTypes.SlingTVOrange]: 'SlingTVOrange',
    [SubscriptionTypes.SlingTVBlue]: 'SlingTVBlue',
    [SubscriptionTypes.SlingTVOrangeAndBlue]: 'SlingTVOrangeAndBlue',
    [SubscriptionTypes.DIRECTVSelect]: 'DIRECTVSelect',
    [SubscriptionTypes.DIRECTVChoice]: 'DIRECTVChoice',
    [SubscriptionTypes.DIRECTVEntertainment]: 'DIRECTVEntertainment',
    [SubscriptionTypes.DIRECTVXtra]: 'DIRECTVXtra',
    [SubscriptionTypes.DIRECTVUltimate]: 'DIRECTVUltimate',
    [SubscriptionTypes.DIRECTVPremier]: 'DIRECTVPremier',
    [SubscriptionTypes.DIRECTVStreamEntertainment]: 'DIRECTVStreamEntertainment',
    [SubscriptionTypes.DIRECTVStreamChoice]: 'DIRECTVStreamChoice',
    [SubscriptionTypes.DIRECTVStreamUltimate]: 'DIRECTVStreamUltimate',
    [SubscriptionTypes.DIRECTVStreamPremier]: 'DIRECTVStreamPremier',
    [SubscriptionTypes.NBALeaguePass]: 'NBALeaguePass',
    [SubscriptionTypes.NBALeaguePassPremium]: 'NBALeaguePassPremium',
    [SubscriptionTypes.NBALeagueTeamPass]: 'NBALeagueTeamPass',
    [SubscriptionTypes.NBALeaguePassIntl]: 'NBALeaguePassIntl',
    [SubscriptionTypes.NBALeaguePassPremiumIntl]: 'NBALeaguePassPremiumIntl',
    [SubscriptionTypes.NBALeagueTeamPassIntl]: 'NBALeagueTeamPassIntl',
    [SubscriptionTypes.CoxStarter]: 'CoxStarter',
    [SubscriptionTypes.CoxPreferred]: 'CoxPreferred',
    [SubscriptionTypes.CoxUltimate]: 'CoxUltimate',
    [SubscriptionTypes.XfinityDigitalStarter]: 'XfinityDigitalStarter',
    [SubscriptionTypes.XfinityDigitalPreferred]: 'XfinityDigitalPreferred',
    [SubscriptionTypes.XfinityDigitalPremier]: 'XfinityDigitalPremier',
    [SubscriptionTypes.XfinityX1Platform]: 'XfinityX1',
    [SubscriptionTypes.SpectrumSelect]: 'SpectrumSelect',
    [SubscriptionTypes.SpectrumSilver]: 'SpectrumSilver',
    [SubscriptionTypes.SpectrumGold]: 'SpectrumGold',
    [SubscriptionTypes.OptimumBroadcastBasic]: 'OptimumBroadcastBasic',
    [SubscriptionTypes.OptimumCore]: 'OptimumCore',
    [SubscriptionTypes.OptimumValue]: 'OptimumValue',
    [SubscriptionTypes.OptimumSelect]: 'OptimumSelect',
    [SubscriptionTypes.OptimumPremier]: 'OptimumPremier',
    [SubscriptionTypes.OptimumEconomy]: 'OptimumEconomy',
    [SubscriptionTypes.Hulu]: 'Hulu',
    [SubscriptionTypes.HuluNoAds]: 'HuluNoAds',
    [SubscriptionTypes.HuluDisneyESPN]: 'HuluDisneyESPN',
    [SubscriptionTypes.HuluDisneyNoAds]: 'HuluDisneyNoAds',
    [SubscriptionTypes.HuluLiveTVDisneyESPN]: 'HuluLiveTVDisneyESPN',
    [SubscriptionTypes.HuluLiveTVDisneyESPNNoAds]: 'HuluLiveTVDisneyESPNNoAds',
    [SubscriptionTypes.MLBTVYearly]: 'MLBTVYearly',
    [SubscriptionTypes.MLBTVMonthly]: 'MLBTVMonthly',
    [SubscriptionTypes.MLBTVSingleTeam]: 'MLBTVSingleTeam',
    [SubscriptionTypes.MLBTVTMobile]: 'MLBTVTMobile',
    [SubscriptionTypes.MLBTVComplimentary]: 'MLBTVComplimentary',
    [SubscriptionTypes.MLBTVInAppPurchase]: 'MLBTVInAppPurchase',
    [SubscriptionTypes.Whitelist]: 'Whitelist',
    [SubscriptionTypes.SpectrumStandardTV]: 'SpectrumStandardTV',
    [SubscriptionTypes.SpectrumChoice]: 'SpectrumChoice',
    [SubscriptionTypes.NBALeaguePassNBATV]: 'NBALeaguePassNBATV',
    [SubscriptionTypes.NBALeaguePassNBATVIntl]: 'NBALeaguePassNBATVIntl',
    [SubscriptionTypes.YouTubeTVFreeTrial]: 'YouTubeTVFreeTrial',
    [SubscriptionTypes.NBALeaguePassIndia]: 'NBALeaguePassIndia',
    [SubscriptionTypes.VerizonFiosTestDrive]: 'VerizonFiosTestDrive',
    [SubscriptionTypes.VerizonYourFios]: 'VerizonYourFios',
    [SubscriptionTypes.VerizonMoreFios]: 'VerizonMoreFios',
    [SubscriptionTypes.VerizonMostFios]: 'VerizonMostFios',
    [SubscriptionTypes.VerizonPreferredHD]: 'VerizonPreferredHD',
    [SubscriptionTypes.VerizonExtremeHD]: 'VerizonExtremeHD',
    [SubscriptionTypes.VerizonUltimateHD]: 'VerizonUltimateHD',
    [SubscriptionTypes.VerizonCustomTV]: 'VerizonCustomTV',
    [SubscriptionTypes.VerizonDoublePlay]: 'VerizonDoublePlay',
    [SubscriptionTypes.VerizonTriplePlay]: 'VerizonTriplePlay',
    [SubscriptionTypes.VerizonTVHD]: 'VerizonTVHD',
    [SubscriptionTypes.WNBALeaguePass]: 'WNBALeaguePass',
    [SubscriptionTypes.DIRECTVPlus]: 'DIRECTVPlus',
    [SubscriptionTypes.DIRECTVMax]: 'DIRECTVMax',
    [SubscriptionTypes.DIRECTVStreamPlus]: 'DIRECTVStreamPlus',
    [SubscriptionTypes.DIRECTVStreamMax]: 'DIRECTVStreamMax',
    [SubscriptionTypes.CoxContourTV]: 'CoxContourTV',
    [SubscriptionTypes.U450]: 'U450',
    [SubscriptionTypes.U400]: 'U400',
    [SubscriptionTypes.U300]: 'U300',
    [SubscriptionTypes.U200]: 'U200',
    [SubscriptionTypes.U100]: 'U100',
    [SubscriptionTypes.Ufamily]: 'Ufamily',
    [SubscriptionTypes.Ubasic]: 'Ubasic',
    [SubscriptionTypes.Uverse]: 'Uverse',
    [SubscriptionTypes.DIRECTV]: 'DIRECTV',
    [SubscriptionTypes.DIRECTVStream]: 'DIRECTVStream',
    [SubscriptionTypes.DIRECTVGoBig]: 'DIRECTVGoBig',
    [SubscriptionTypes.DIRECTVStreamGoBig]: 'DIRECTVStreamGoBig',
    [SubscriptionTypes.DIRECTVLiveALittle]: 'DIRECTVLiveALittle',
    [SubscriptionTypes.DIRECTVStreamLiveALittle]: 'DIRECTVStreamLiveALittle',
    [SubscriptionTypes.DIRECTVJustRight]: 'DIRECTVJustRight',
    [SubscriptionTypes.DIRECTVStreamJustRight]: 'DIRECTVStreamJustRight',
    [SubscriptionTypes.DIRECTVGottaHaveIt]: 'DIRECTVGottaHaveIt',
    [SubscriptionTypes.DIRECTVStreamGottaHaveIt]: 'DIRECTVStreamGottaHaveIt',
    [SubscriptionTypes.HuluNoAdsLiveTV]: 'HuluNoAdsLiveTV',
    [SubscriptionTypes.SpectrumTV]: 'SpectrumTV',
    [SubscriptionTypes.PeacockFree]: 'PeacockFree',
    [SubscriptionTypes.PeacockPremium]: 'PeacockPremium',
    [SubscriptionTypes.PeacockPremiumPlus]: 'PeacockPremiumPlus',
};

export enum AddonTypes {
    YoutubeTVLeaguePass = 0,
    YoutubeTVSportsPlus = 1,
    FuboTVSportsPlus = 2,
    FuboTVExtra = 3,
    FuboTVInternationalSportsPlus = 4,
    FuboTVAdventurePlus = 5,
    FuboTVNBALeaguePass = 6,
    FuboTVSportsLite = 7,
    FuboTVLatinoPlus = 8,
    FuboTVRAIItalia = 9,
    FuboTVTV5MONDE = 10,
    FuboTVPortuguesePlus = 11,
    SlingTVTotalTVDeal = 12,
    SlingTVSportsExtras = 13,
    SlingTVWorldSportsMini = 14,
    SlingTVNBALeaguePass = 15,
    SlingTVNBATeamPass = 16,
    SlingTVSlingDeportes = 17,
    DIRECTVSportsPack = 18,
    DIRECTVNFLSundayTicket = 19,
    DIRECTVMLBExtraInnings = 20,
    DIRECTVNBALeaguePass = 21,
    DIRECTVFOXSoccerPlus = 22,
    DIRECTVMLSDirectKick = 23,
    DIRECTVNHLCenterIce = 24,
    DIRECTVDeportes = 25,
    DIRECTVStreamNBALeaguePass = 26,
    DIRECTVStreamDeportes = 27,
    CoxSportsAndInfoPack = 28,
    CoxSportsPack2 = 29,
    XfinitySportsAndEntertainment = 30,
    XfinityLeaguePass = 31,
    XfinityCenterIce = 32,
    XfinitySportsAndNews = 33,
    SpectrumSportsPack = 34,
    SpectrumNBALeaguePass = 35,
    SpectrumRedzone = 36,
    SpectrumBeinSports = 37,
    SpectrumMLSDirectKick = 38,
    SpectrumFoxSoccerPlus = 39,
    SpectrumNHLCenterIce = 40,
    HuluESPN = 41,
    HuluSports = 42,
    VerizonNHLCenterIce = 43,
    VerizonNBALeaguePass = 44,
    VerizonMLBExtraInnings = 45,
    VerizonMLBTVPremium = 46,
    VerizonMLSDirectKick = 47,
    VerizonFoxSoccerPlus = 48,
    VerizonSportsPass = 49,
    VerizonYourFiosTNT = 50,
    VerizonYourFiosESPN = 51,
    UverseSports = 52,
}

export const addonsToNames: { [key in AddonTypes]: string } = {
    [AddonTypes.YoutubeTVLeaguePass]: 'YoutubeTVLeaguePass',
    [AddonTypes.YoutubeTVSportsPlus]: 'YoutubeTVSportsPlus',
    [AddonTypes.FuboTVSportsPlus]: 'FuboTVSportsPlus',
    [AddonTypes.FuboTVExtra]: 'FuboTVExtra',
    [AddonTypes.FuboTVInternationalSportsPlus]: 'FuboTVInternationalSportsPlus',
    [AddonTypes.FuboTVAdventurePlus]: 'FuboTVAdventurePlus',
    [AddonTypes.FuboTVNBALeaguePass]: 'FuboTVNBALeaguePass',
    [AddonTypes.FuboTVSportsLite]: 'FuboTVSportsLite',
    [AddonTypes.FuboTVLatinoPlus]: 'FuboTVLatinoPlus',
    [AddonTypes.FuboTVRAIItalia]: 'FuboTVRAIItalia',
    [AddonTypes.FuboTVTV5MONDE]: 'FuboTVTV5MONDE',
    [AddonTypes.FuboTVPortuguesePlus]: 'FuboTVPortuguesePlus',
    [AddonTypes.SlingTVTotalTVDeal]: 'SlingTVTotalTVDeal',
    [AddonTypes.SlingTVSportsExtras]: 'SlingTVSportsExtras',
    [AddonTypes.SlingTVWorldSportsMini]: 'SlingTVWorldSportsMini',
    [AddonTypes.SlingTVNBALeaguePass]: 'SlingTVNBALeaguePass',
    [AddonTypes.SlingTVNBATeamPass]: 'SlingTVNBATeamPass',
    [AddonTypes.SlingTVSlingDeportes]: 'SlingTVSlingDeportes',
    [AddonTypes.DIRECTVSportsPack]: 'DIRECTVSportsPack',
    [AddonTypes.DIRECTVNFLSundayTicket]: 'DIRECTVNFLSundayTicket',
    [AddonTypes.DIRECTVMLBExtraInnings]: 'DIRECTVMLBExtraInnings',
    [AddonTypes.DIRECTVNBALeaguePass]: 'DIRECTVNBALeaguePass',
    [AddonTypes.DIRECTVFOXSoccerPlus]: 'DIRECTVFOXSoccerPlus',
    [AddonTypes.DIRECTVMLSDirectKick]: 'DIRECTVMLSDirectKick',
    [AddonTypes.DIRECTVNHLCenterIce]: 'DIRECTVNHLCenterIce',
    [AddonTypes.DIRECTVDeportes]: 'DIRECTVDeportes',
    [AddonTypes.DIRECTVStreamNBALeaguePass]: 'DIRECTVStreamNBALeaguePass',
    [AddonTypes.DIRECTVStreamDeportes]: 'DIRECTVStreamDeportes',
    [AddonTypes.CoxSportsAndInfoPack]: 'CoxSportsAndInfoPack',
    [AddonTypes.CoxSportsPack2]: 'CoxSportsPack2',
    [AddonTypes.XfinitySportsAndEntertainment]: 'XfinitySportsAndEntertainment',
    [AddonTypes.XfinityLeaguePass]: 'XfinityLeaguePass',
    [AddonTypes.XfinityCenterIce]: 'XfinityCenterIce',
    [AddonTypes.XfinitySportsAndNews]: 'XfinitySportsAndNews',
    [AddonTypes.HuluESPN]: 'HuluESPN',
    [AddonTypes.HuluSports]: 'HuluSports',
    [AddonTypes.VerizonNHLCenterIce]: 'VerizonNHLCenterIce',
    [AddonTypes.VerizonNBALeaguePass]: 'VerizonNBALeaguePass',
    [AddonTypes.VerizonMLBExtraInnings]: 'VerizonMLBExtraInnings',
    [AddonTypes.VerizonMLBTVPremium]: 'VerizonMLBTVPremium',
    [AddonTypes.VerizonMLSDirectKick]: 'VerizonMLSDirectKick',
    [AddonTypes.VerizonFoxSoccerPlus]: 'VerizonFoxSoccerPlus',
    [AddonTypes.VerizonSportsPass]: 'VerizonSportsPass',
    [AddonTypes.VerizonYourFiosTNT]: 'VerizonYourFiosTNT',
    [AddonTypes.VerizonYourFiosESPN]: 'VerizonYourFiosESPN',
    [AddonTypes.UverseSports]: 'UverseSports',
    [AddonTypes.SpectrumSportsPack]: 'SpectrumSportsPack',
    [AddonTypes.SpectrumNBALeaguePass]: 'SpectrumNBALeaguePass',
    [AddonTypes.SpectrumRedzone]: 'SpectrumRedzone',
    [AddonTypes.SpectrumBeinSports]: 'SpectrumBeinSports',
    [AddonTypes.SpectrumMLSDirectKick]: 'SpectrumMLSDirectKick',
    [AddonTypes.SpectrumFoxSoccerPlus]: 'SpectrumFoxSoccerPlus',
    [AddonTypes.SpectrumNHLCenterIce]: 'SpectrumNHLCenterIce',
};

export enum RestrictionTypes {
    None = 0,
    NBAPlayoffs = 1,
    WWE = 2,
    WNBA = 3,
    NBASummerLeague = 4,
    MLB = 5,
}

export const restrictionTypesToNames: { [key in RestrictionTypes]: string } = {
    [RestrictionTypes.None]: 'None',
    [RestrictionTypes.NBAPlayoffs]: 'NBA Playoffs',
    [RestrictionTypes.WWE]: 'US National Cable',
    [RestrictionTypes.WNBA]: 'WNBA',
    [RestrictionTypes.NBASummerLeague]: 'NBA Summer League',
    [RestrictionTypes.MLB]: 'MLB',
};

export const vpnPlatforms: Platforms[] = [Platforms.NBALeaguePass];

export const gigglProxyPlatforms: Platforms[] = [Platforms.Hulu];

export const greendeskProxyPlatforms: Platforms[] = [];

export const ipRoyalProxyPlatforms: Platforms[] = [];

export type PlatformConfig = {
    platform: Platforms;
    displayUrl: string;
    loginUrl: string;
    passwordResetUrl: string;
    usernameType: 'email' | 'text';
    usernameCopy: string;
    usernamePlaceholder?: string;
    desktopOnly?: boolean;
    caveatText?: string;
};

export const platforms: PlatformConfig[] = [
    {
        platform: Platforms.YouTubeTV,
        displayUrl: 'tv.youtube.com',
        loginUrl:
            'https://accounts.google.com/signin/v2/identifier?continue=http%3A%2F%2Fm.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26feature%3Dupg_web_login%26skip_identity_prompt%3DTrue%26hl%3Den%26next%3Dhttps%253A%252F%252Ftv.youtube.com%252F%253Futm_servlet%253Dexp%2526onboard%253D3&service=youtube&sacu=1&hl=en&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin',
        passwordResetUrl:
            'https://accounts.google.com/signin/v2/identifier?continue=http%3A%2F%2Fm.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26feature%3Dupg_web_login%26skip_identity_prompt%3DTrue%26hl%3Den%26next%3Dhttps%253A%252F%252Ftv.youtube.com%252F%253Futm_servlet%253Dexp%2526onboard%253D3&service=youtube&sacu=1&hl=en&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        platform: Platforms.Hulu,
        displayUrl: 'hulu.com',
        loginUrl: 'https://www.hulu.com/welcome',
        passwordResetUrl: 'https://auth.hulu.com/find_account',
        usernameType: 'email',
        usernameCopy: 'Email',
        caveatText: 'Live events are only available with Hulu + Live TV plans.',
    },
    {
        platform: Platforms.NBALeaguePass,
        displayUrl: 'watch.nba.com',
        loginUrl: 'https://watch.nba.com',
        passwordResetUrl: 'https://watch.nba.com',
        usernameType: 'email',
        usernameCopy: 'Email address',
        caveatText:
            'Nationally broadcast games, including playoffs, are only available on international NBA League Pass plans.',
    },
    {
        platform: Platforms.WNBALeaguePass,
        displayUrl: 'leaguepass.wnba.com',
        loginUrl: 'https://leaguepass.wnba.com/login',
        passwordResetUrl: 'https://leaguepass.wnba.com/login/request-password',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        platform: Platforms.Xfinity,
        displayUrl: 'xfinity.com',
        loginUrl: 'https://login.xfinity.com/',
        passwordResetUrl: 'https://idm.xfinity.com/myaccount/lookup',
        usernameType: 'text',
        usernameCopy: 'Xfinity ID',
        usernamePlaceholder: 'Email, mobile, or username',
    },
    {
        platform: Platforms.DIRECTV,
        displayUrl: 'directv.com',
        loginUrl:
            'https://signin.att.com/dynamic/iamLRR/LrrController?IAM_OP=login&appName=m45917&loginSuccessURL=https%3A%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps%3A%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps%3A%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp',
        passwordResetUrl:
            'https://identity.att.com/identity-ui/fpwd/lander?origination_point=tguard&trid=bea22b253a734ffcdadf8d50c7e04c8b776e5ab9&appName=m45917&Return_URL=https:%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps:%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps:%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp&Cancel_URL=https:%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps:%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps:%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp&lang=en-us',
        usernameType: 'text',
        usernameCopy: 'User ID',
    },
    {
        platform: Platforms.Spectrum,
        displayUrl: 'spectrum.net',
        loginUrl: 'https://id.spectrum.net/login',
        passwordResetUrl: 'https://id.spectrum.net/recover',
        usernameType: 'text',
        usernameCopy: 'Username',
    },
    {
        platform: Platforms.Verizon,
        displayUrl: 'verizon.com/watch',
        loginUrl:
            'https://secure.verizon.com/vzauth/UI/Login?goto=https://www.verizon.com/consumer/myverizon/router?Target=https%3A%2F%2Ftv.verizon.com%2Fwatch%2F',
        passwordResetUrl:
            'https://secure.verizon.com/account/forgot-password/start?goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3FTarget%3Dhttps%3A%2F%2Ftv.verizon.com%2Fwatch%2F',
        usernameType: 'text',
        usernameCopy: 'User ID or Verizon mobile number',
    },
    {
        platform: Platforms.Cox,
        displayUrl: 'cox.com',
        loginUrl: 'https://www.cox.com/content/dam/cox/okta/signin.html',
        passwordResetUrl: 'https://www.cox.com/myprofile/forgot-password.html?finalview=',
        usernameType: 'text',
        usernameCopy: 'User ID',
    },
    {
        platform: Platforms.Optimum,
        displayUrl: 'optimum.net',
        loginUrl: 'https://www.optimum.net/login/',
        passwordResetUrl: 'https://www.optimum.net/reset-password/',
        usernameType: 'text',
        usernameCopy: 'Optimum ID',
    },
    {
        platform: Platforms.Uverse,
        displayUrl: 'www.att.com/u-verse-tv',
        loginUrl: 'https://www.att.com/acctmgmt/login',
        passwordResetUrl: 'https://identity.att.com/identity-ui/fpwd/lander',
        usernameType: 'text',
        usernameCopy: 'User ID',
    },
    {
        platform: Platforms.SlingTV,
        displayUrl: 'sling.com',
        loginUrl: 'https://www.sling.com/sign-in',
        passwordResetUrl: 'https://www.sling.com/sign-in/forgot-password',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        platform: Platforms.FuboTV,
        displayUrl: 'fubo.tv',
        loginUrl: 'https://www.fubo.tv/signin',
        passwordResetUrl: 'https://www.fubo.tv/signin',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        platform: Platforms.MLBTV,
        displayUrl: 'mlb.tv',
        loginUrl: 'https://www.mlb.com/login?redirectUri=/tv',
        passwordResetUrl: 'https://www.mlb.com/forgot-password',
        usernameType: 'text',
        usernameCopy: 'Username',
    },
    {
        platform: Platforms.Peacock,
        displayUrl: 'peacocktv.com/',
        loginUrl: 'https://www.peacocktv.com/signin',
        passwordResetUrl: 'https://www.peacocktv.com/forgot',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
];
