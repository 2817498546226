import { PayloadAction } from '@reduxjs/toolkit';
import { ChannelType, MessageResponse } from '@internal/text-chat/types';

export interface WithId {
    id: string;
}

export interface NormalizedState<T extends WithId> {
    byId: {
        [id: string]: T;
    };
    allIds: string[];
}

export interface PaginationState extends NormalizedState<MessageResponse> {
    loading: boolean;
    hasMore: boolean;
    lastPage: MessageResponse[];
}

export type StreamAction<T> = PayloadAction<
    {
        type: ChannelType;
    } & T
>;

export enum NotificationType {
    CopiedLink = 'Copied Link',
    CopiedInviteLink = 'Copied Invite Link',
    ParticipantJoin = 'Participant Join',
    ParticipantDisconnect = 'Participant Disconnect',
    HostEndBroadcast = 'Host End Broadcast',
    HostPauseBroadcast = 'Host Pause Broadcast',
    HostSourceMuted = 'Host Source Muted',
    UnsupportedSite = 'Unsupported Site',
    HostPoorConnection = 'Host Poor Connection',
    HostRoomCreated = 'Host Room Created',
    MediaPermissionsMissing = 'Media Permissions Missing',
    CameraPermissionMissing = 'Camera Permission Missing',
    MicPermissionMissing = 'Mic Permission Missing',
    LocationServicesEnabled = 'Location Services Enabled',
    LocationServicesDisabled = 'Location Services Disabled',
    InRoomFeedback = 'In-Room Feedback',
    PmfFeedback = 'Product Market Fit Survey',
    ParticipantWinner = 'Participant Winner',
    ParticipantLoser = 'Participant Loser',
    WebDownloadAppPrompt = 'Desktop App Download Prompt',
    iPhoneAppDownloadPrompt = 'iPhone App Download Prompt',
    iPhoneAppEmailConfirmation = 'iPhone App Email Confirmation',
    iPhoneAppQrCode = 'iPhone App QR Code',
    CameraWarning = 'Camera Warning',
    ShareStream = 'Share Stream',
    ShareStreamPending = 'Share Stream Pending',
    ShareStreamConfirmation = 'Share Stream Confirmation',
    StreamTakeover = 'Stream Takeover',
    MagicEmail = 'Magic Email',
    MicMuted = 'Mic Muted',
    StageInvite = 'Stage Invite',
    RoomSignupConfirmation = 'Room Signup Confirmation',
    RoomArchived = 'Room Archived',
    RoomDeleted = 'Room Deleted',
    MemberBanned = 'Member Banned',
    ChatWindowBlocked = 'Chat Window Blocked',
    TvAuthConnected = 'TV Auth Connected',
    TvAuthVerified = 'TV Auth Verified',
    TvAuthAccessDenied = 'TV Auth Access Denied',
    TvAuthParseFailed = 'TV Auth Parse Failed',
    TvAuthStreamAccessRevoked = 'TV Auth Stream Access Revoked',
    TvAuthPortalPending = 'TV Auth Portal Pending',
    TvAuthPlatformError = 'TV Auth Platform Error',
    FlairTipConfirmation = 'Flair Tip Confirmation',
}

export type PopupNotification = {
    type: NotificationType;
    payload?: any;
    duration?: number;
};
