import { customAlphabet } from 'nanoid';
import { Configuration } from '@playback-sports/ion-sdk-js/lib/client';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const customNanoid = customAlphabet(alphabet, 10);

export const nanoid = (): string => {
    return customNanoid();
};

export type CapturableElement = HTMLMediaElement & {
    captureStream: () => MediaStream;
};

const TURN_SERVERS = [
    'turn:coturn.getplayback.dev:3478',
    'turn:coturn-1.getplayback.dev:3478',
    'turn:coturn-2.getplayback.dev:3478',
    'turn:coturn-3.getplayback.dev:3478',
];

function getRandomTurnServer() {
    const index = Math.floor(Math.random() * TURN_SERVERS.length);
    return TURN_SERVERS[index];
}

export const generateIonClientConfig = (
    turnServer: number | undefined = undefined
): Configuration & { sdpSemantics: string } => {
    let urls: string | string[] = getRandomTurnServer();
    if (turnServer !== undefined && turnServer >= 0 && turnServer < TURN_SERVERS.length) {
        urls = TURN_SERVERS[turnServer];
    }

    return {
        iceServers: [
            // {
            //     urls: 'stun:coturn.getplayback.dev:3478',
            // },
            {
                urls,
                username: 'admin',
                credentialType: 'password',
                credential: 'qDYbEBHyBZQ3HkFah7jetuDE',
            },
        ],
        codec: 'h264',
        sdpSemantics: 'unified-plan',
        iceTransportPolicy: 'relay',
    };
};

export const PUBLISHER_ION_CONFIG: any = {
    iceServers: [
        {
            urls: [
                'stun:stun.l.google.com:19302',
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
            ],
        },
        {
            urls: [
                'turn:coturn.getplayback.dev:3478',
                'turn:coturn-1.getplayback.dev:3478',
                'turn:coturn-2.getplayback.dev:3478',
                'turn:coturn-3.getplayback.dev:3478',
            ],
            username: 'admin',
            credentialType: 'password',
            credential: 'qDYbEBHyBZQ3HkFah7jetuDE',
        },
    ],
    codec: 'h264',
    // Force unified plan
    sdpSemantics: 'unified-plan',
};
