// WebRTC stats types. Loosely based around https://w3c.github.io/webrtc-stats

export enum StatsType {
    Codec = 'codec',
    InboundRTP = 'inbound-rtp',
    OutboundRTP = 'outbound-rtp',
    RemoteInboundRTP = 'remote-inbound-rtp',
    RemoteOutboundRTP = 'remote-outbound-rtp',
    MediaSource = 'media-source',
    CSRC = 'csrc',
    PeerConnection = 'peer-connection',
    DataChannel = 'data-channel',
    Stream = 'stream',
    Track = 'track',
    Transceiver = 'transceiver',
    Sender = 'sender',
    Receiver = 'receiver',
    Transport = 'transport',
    SCTPTransport = 'sctp-transport',
    CandidatePair = 'candidate-pair',
    LocalCandidate = 'local-candidate',
    RemoteCandidate = 'remote-candidate',
    Certificate = 'certificate',
    IceServer = 'ice-server',
}

export enum RTCDtlsTransportState {
    RTCDtlsTransportStateNew = 'new',
    RTCDtlsTransportStateConnection = 'connecting',
    RTCDtlsTransportStateConnected = 'connected',
    RTCDtlsTransportStateClosed = 'closed',
    RTCDtlsTransportStateFailed = 'failed',
}

export interface RTCStats {
    timestamp: number;
    type: StatsType;
    id: string;
}

export interface RTCRtpStreamStats extends RTCStats {
    ssrc: string;
    kind: string;
    transportId: string;
    codecId: string;
    mediaType: string;
}

export interface RTCSentRtpStreamStats extends RTCRtpStreamStats {
    packetsSent: number;
    bytesSent: number;
}

export interface RTCReceivedRtpStreamStats extends RTCRtpStreamStats {
    packetsReceived: number;
    packetsLost: number;
    jitter: number;
}

export interface RTCOutboundRtpStreamStats extends RTCSentRtpStreamStats {
    isRemote: boolean;
    firCount: number;
    pliCount: number;
    nackCount: number;
    sliCount?: number;
    qpSum: number;
    packetsDiscardedOnSend?: number;
    fecPacketsSent?: number;
    retransmittedBytesSent: number;
    retransmittedPacketsSent: number;
    headerBytesSent: number;
    bytesDiscardedOnSend?: number;
    trackId: string;
    senderId?: string;
    remoteId?: string;
    rid?: string;
    rtxSsrc?: number;
    mediaSourceId: string;
    lastPacketSentTimestamp?: number;
    totalSamplesSent?: number;
    targetBitrate?: number;
    framesEncoded: number;
    keyFramesEncoded: number;
    frameWidth: number;
    frameHeight: number;
    frameBitDepth?: number;
    framesPerSecond: number;
    framesSent: number;
    hugeFramesSent: number;
    totalPacketSendDelay: number;
    framesDiscardedOnSend?: number;
    totalEncodeTime: number;
    averageRtcpInterval?: number;
    qualityLimitationReason: string;
    totalEncodedBytesTarget: number;
    qualityLimitationResolutionChanges: number;
    encoderImplementation: string;
}

export interface RTCInboundRTPStreamStats extends RTCReceivedRtpStreamStats {
    audioLevel: number;
    bytesReceived: number;
    decoderImplementation: string;
    firCount: number;
    frameHeight: number;
    frameWidth: number;
    framesDecoded: number;
    framesDropped: number;
    framesPerSecond: number;
    framesReceived: number;
    headerBytesReceived: number;
    isRemote: boolean;
    keyFramesDecoded: number;
    lastPacketReceivedTimestamp: number;
    nackCount: number;
    pliCount: number;
    totalDecodeTime: number;
    totalInterFrameDelay: number;
    totalSquaredInterFrameDelay: number;
    trackId: string;
    concealedSamples: number;
    concealmentEvents: number;
    fecPacketsDiscarded: number;
    fecPacketsReceived: number;
    insertedSamplesForDeceleration: number;
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    removedSamplesForAcceleration: number;
    silentConcealedSamples: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    totalSamplesReceived: number;
}

export interface RTCMediaSourceStats extends RTCStats {
    trackIdentifier: string;
    kind: string;
    relayedSource?: boolean;
}

export interface RTCAudioSourceStats extends RTCMediaSourceStats {
    audioLevel: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    echoReturnLoss?: number;
    echoReturnLossEnhancement?: number;
}

export interface RTCVideoSourceStats extends RTCMediaSourceStats {
    width?: number;
    height?: number;
    bitDepth?: number;
    frames?: number;
    framesPerSecond: number;
}

export interface RTCTransportStats extends RTCStats {
    packetsSent: number;
    packetsReceived: number;
    bytesSent: number;
    bytesReceived: number;
    rtcpTransportStatsId?: string;
    dtlsState: RTCDtlsTransportState;
    selectedCandidatePairId: string;
    localCertificateId: string;
    remoteCertificateId: string;
    tlsVersion: string;
    dtlsCipher: string;
    srtpCipher: string;
    tlsGroup?: string;
    selectedCandidatePairChanges: number;
}

export interface RTCOutboundMediaStreamTrackStats extends RTCMediaSourceStats {
    mediaSourceId: string;
    remoteSource: boolean;
    ended: boolean;
    detached: boolean;
    frameWidth?: number;
    frameHeight?: number;
    framesSent?: number;
    hugeFramesSent?: number;
    framesDecoded: number;
    framesDropped: number;
    framesReceived: number;
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    removedSamplesForAcceleration: number;
    silentConcealedSamples: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    totalSamplesReceived: number;
}
export interface RTCInboundMediaStreamTrackStats extends RTCMediaSourceStats {
    detached: boolean;
    ended: boolean;
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    remoteSource: boolean;
}

export interface RTCInboundVideoStreamTrackStats extends RTCInboundMediaStreamTrackStats {
    frameHeight: number;
    frameWidth: number;
    framesDecoded: number;
    framesDropped: number;
    framesReceived: number;
    freezeCount: number; // missing in chrome
    pauseCount: number; // missing in chrome
    totalFramesDuration: number; // missing in chrome
    totalFreezesDuration: number; // missing in chrome
    totalPausesDuration: number; // missing in chrome
    sumOfSquaredFramesDuration: number; // missing in chrome
}

export interface RTCInboundAudioStreamTrackStats extends RTCInboundMediaStreamTrackStats {
    audioLevel: number;
    concealedSamples: number;
    concealmentEvents: number;
    insertedSamplesForDeceleration: number;
    removedSamplesForAcceleration: number;
    silentConcealedSamples: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    totalSamplesReceived: number;
    jitterBufferFlushes: number; // missing in chrome
}

export interface RTCMediaStreamStats extends RTCStats {
    streamIdentifer: string;
    trackIds: Array<string>;
}

// Uplot specific types

interface ITimestamp {
    timestamp: number;
}
class TimestampedData {
    timestamp: number;

    constructor(data: ITimestamp) {
        this.timestamp = data.timestamp / 1000.0;
    }
}

class TimestampSeries {
    timestamps: Array<number>;

    constructor() {
        this.timestamps = [];
    }
}

export class WebRTCTransportData extends TimestampedData {
    packetsSent: number;
    packetsReceived: number;
    bytesSent: number;
    bytesReceived: number;
    selectedCandidatePairChanges: number;

    constructor(stats: RTCTransportStats) {
        super(stats);
        this.packetsSent = stats.packetsSent;
        this.packetsReceived = stats.packetsReceived;
        this.bytesSent = stats.bytesSent;
        this.bytesReceived = stats.bytesReceived;
        this.selectedCandidatePairChanges = stats.selectedCandidatePairChanges;
    }
}

export class WebRTCTransportSeries extends TimestampSeries {
    packetsSent: Array<number>;
    packetsReceived: Array<number>;
    bytesSent: Array<number>;
    bytesReceived: Array<number>;
    selectedCandidatePairChanges: Array<number>;

    constructor() {
        super();
        this.packetsSent = [];
        this.packetsReceived = [];
        this.bytesSent = [];
        this.bytesReceived = [];
        this.selectedCandidatePairChanges = [];
    }

    push(transportData: WebRTCTransportData) {
        this.timestamps.push(transportData.timestamp);
        this.packetsSent.push(transportData.packetsSent);
        this.packetsReceived.push(transportData.packetsReceived);
        this.bytesSent.push(transportData.bytesSent);
        this.bytesReceived.push(transportData.bytesReceived);
        this.selectedCandidatePairChanges.push(transportData.selectedCandidatePairChanges);
    }
}

export class WebRTCOutboundAudioRtpStreamData extends TimestampedData {
    packetsSent: number;
    bytesSent: number;
    retransmittedBytesSent: number;
    retransmittedPacketsSent: number;
    headerBytesSent: number;

    constructor(stats: RTCOutboundRtpStreamStats) {
        super(stats);
        this.packetsSent = stats.packetsSent;
        this.bytesSent = stats.bytesSent;
        this.retransmittedBytesSent = stats.retransmittedBytesSent;
        this.retransmittedPacketsSent = stats.retransmittedPacketsSent;
        this.headerBytesSent = stats.headerBytesSent;
    }
}

export class WebRTCInboundAudioRtpStreamData extends TimestampedData {
    packetsReceived: number;
    packetsLost: number;
    bytesReceived: number;
    audioLevel: number;
    headerBytesReceived: number;
    concealedSamples: number;
    concealmentEvents: number;
    fecPacketsDiscarded: number;
    fecPacketsReceived: number;
    insertedSamplesForDeceleration: number;
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    removedSamplesForAcceleration: number;
    silentConcealedSamples: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    totalSamplesReceived: number;

    constructor(stats: RTCInboundRTPStreamStats) {
        super(stats);
        this.packetsReceived = stats.packetsReceived;
        this.packetsLost = stats.packetsLost;
        this.bytesReceived = stats.bytesReceived;
        this.audioLevel = stats.audioLevel;
        this.headerBytesReceived = stats.headerBytesReceived;
        this.concealedSamples = stats.concealedSamples;
        this.concealmentEvents = stats.concealmentEvents;
        this.fecPacketsDiscarded = stats.fecPacketsDiscarded;
        this.fecPacketsReceived = stats.fecPacketsReceived;
        this.insertedSamplesForDeceleration = stats.insertedSamplesForDeceleration;
        this.jitterBufferDelay = stats.jitterBufferDelay;
        this.jitterBufferEmittedCount = stats.jitterBufferEmittedCount;
        this.removedSamplesForAcceleration = stats.removedSamplesForAcceleration;
        this.silentConcealedSamples = stats.silentConcealedSamples;
        this.totalAudioEnergy = stats.totalAudioEnergy;
        this.totalSamplesDuration = stats.totalSamplesDuration;
        this.totalSamplesReceived = stats.totalSamplesReceived;
    }
}

export class WebRTCOutboundVideoRtpStreamData extends WebRTCOutboundAudioRtpStreamData {
    firCount: number;
    pliCount: number;
    nackCount: number;
    qpSum: number;
    framesEncoded: number;
    keyFramesEncoded: number;
    frameWidth: number;
    frameHeight: number;
    frameBitDepth?: number;
    framesPerSecond: number;
    framesSent: number;
    hugeFramesSent: number;
    totalPacketSendDelay: number;
    totalEncodeTime: number;
    totalEncodedBytesTarget: number;
    qualityLimitationResolutionChanges: number;

    constructor(stats: RTCOutboundRtpStreamStats) {
        super(stats);
        this.packetsSent = stats.packetsSent;
        this.bytesSent = stats.bytesSent;
        this.firCount = stats.firCount;
        this.pliCount = stats.pliCount;
        this.nackCount = stats.nackCount;
        this.qpSum = stats.qpSum;
        this.retransmittedBytesSent = stats.retransmittedBytesSent;
        this.retransmittedPacketsSent = stats.retransmittedPacketsSent;
        this.headerBytesSent = stats.headerBytesSent;
        this.framesEncoded = stats.framesEncoded;
        this.keyFramesEncoded = stats.keyFramesEncoded;
        this.frameWidth = stats.frameWidth;
        this.frameHeight = stats.frameHeight;
        this.frameBitDepth = stats.frameBitDepth;
        this.framesPerSecond = stats.framesPerSecond;
        this.framesSent = stats.framesSent;
        this.hugeFramesSent = stats.hugeFramesSent;
        this.totalPacketSendDelay = stats.totalPacketSendDelay;
        this.totalEncodeTime = stats.totalEncodeTime;
        this.totalEncodedBytesTarget = stats.totalEncodedBytesTarget;
        this.qualityLimitationResolutionChanges = stats.qualityLimitationResolutionChanges;
    }
}

export class WebRTCInboundVideoRtpStreamData extends TimestampedData {
    packetsReceived: number;
    packetsLost: number;
    bytesReceived: number;
    firCount: number;
    framesPerSecond: number;
    framesReceived: number;
    headerBytesReceived: number;
    framesDecoded: number;
    keyFramesDecoded: number;
    nackCount: number;
    pliCount: number;
    totalDecodeTime: number;
    totalInterFrameDelay: number;
    totalSquaredInterFrameDelay: number;

    constructor(stats: RTCInboundRTPStreamStats) {
        super(stats);
        this.packetsReceived = stats.packetsReceived;
        this.packetsLost = stats.packetsLost;
        this.bytesReceived = stats.bytesReceived;
        this.firCount = stats.firCount;
        this.framesPerSecond = stats.framesPerSecond;
        this.framesReceived = stats.framesReceived;
        this.headerBytesReceived = stats.headerBytesReceived;
        this.keyFramesDecoded = stats.keyFramesDecoded;
        this.framesDecoded = stats.framesDecoded;
        this.nackCount = stats.nackCount;
        this.pliCount = stats.pliCount;
        this.totalDecodeTime = stats.totalDecodeTime;
        this.totalInterFrameDelay = stats.totalInterFrameDelay;
        this.totalSquaredInterFrameDelay = stats.totalSquaredInterFrameDelay;
    }
}

export class WebRTCOutboundAudioRtpStreamSeries extends TimestampSeries {
    packetsSent: Array<number>;
    bytesSent: Array<number>;
    retransmittedBytesSent: Array<number>;
    retransmittedPacketsSent: Array<number>;
    headerBytesSent: Array<number>;

    constructor() {
        super();
        this.packetsSent = [];
        this.bytesSent = [];
        this.retransmittedBytesSent = [];
        this.retransmittedPacketsSent = [];
        this.headerBytesSent = [];
    }

    push(outboundData: WebRTCOutboundAudioRtpStreamData) {
        this.timestamps.push(outboundData.timestamp);
        this.packetsSent.push(outboundData.packetsSent);
        this.bytesSent.push(outboundData.bytesSent);
        this.retransmittedBytesSent.push(outboundData.retransmittedBytesSent);
        this.retransmittedPacketsSent.push(outboundData.retransmittedPacketsSent);
        this.headerBytesSent.push(outboundData.headerBytesSent);
    }
}

export class WebRTCInboundAudioRtpStreamSeries extends TimestampSeries {
    packetsReceived: Array<number>;
    packetsLost: Array<number>;
    bytesReceived: Array<number>;
    audioLevel: Array<number>;
    headerBytesReceived: Array<number>;
    concealedSamples: Array<number>;
    concealmentEvents: Array<number>;
    fecPacketsDiscarded: Array<number>;
    fecPacketsReceived: Array<number>;
    insertedSamplesForDeceleration: Array<number>;
    jitterBufferDelay: Array<number>;
    jitterBufferEmittedCount: Array<number>;
    removedSamplesForAcceleration: Array<number>;
    silentConcealedSamples: Array<number>;
    totalAudioEnergy: Array<number>;
    totalSamplesDuration: Array<number>;
    totalSamplesReceived: Array<number>;

    constructor() {
        super();
        this.packetsReceived = [];
        this.packetsLost = [];
        this.bytesReceived = [];
        this.audioLevel = [];
        this.headerBytesReceived = [];
        this.concealedSamples = [];
        this.concealmentEvents = [];
        this.fecPacketsDiscarded = [];
        this.fecPacketsReceived = [];
        this.insertedSamplesForDeceleration = [];
        this.jitterBufferDelay = [];
        this.jitterBufferEmittedCount = [];
        this.removedSamplesForAcceleration = [];
        this.silentConcealedSamples = [];
        this.totalAudioEnergy = [];
        this.totalSamplesDuration = [];
        this.totalSamplesReceived = [];
    }

    push(inboundData: WebRTCInboundAudioRtpStreamData) {
        this.timestamps.push(inboundData.timestamp);
        this.packetsReceived.push(inboundData.packetsReceived);
        this.packetsLost.push(inboundData.packetsLost);
        this.bytesReceived.push(inboundData.bytesReceived);
        this.audioLevel.push(inboundData.audioLevel);
        this.headerBytesReceived.push(inboundData.headerBytesReceived);
        this.concealedSamples.push(inboundData.concealedSamples);
        this.concealmentEvents.push(inboundData.concealmentEvents);
        this.fecPacketsDiscarded.push(inboundData.fecPacketsDiscarded);
        this.fecPacketsReceived.push(inboundData.fecPacketsReceived);
        this.insertedSamplesForDeceleration.push(inboundData.insertedSamplesForDeceleration);
        this.jitterBufferDelay.push(inboundData.jitterBufferDelay);
        this.jitterBufferEmittedCount.push(inboundData.jitterBufferEmittedCount);
        this.removedSamplesForAcceleration.push(inboundData.removedSamplesForAcceleration);
        this.silentConcealedSamples.push(inboundData.silentConcealedSamples);
        this.totalAudioEnergy.push(inboundData.totalAudioEnergy);
        this.totalSamplesDuration.push(inboundData.totalSamplesDuration);
        this.totalSamplesReceived.push(inboundData.totalSamplesReceived);
    }
}
export class WebRTCOutboundVideoRtpStreamSeries extends WebRTCOutboundAudioRtpStreamSeries {
    firCount: Array<number>;
    pliCount: Array<number>;
    nackCount: Array<number>;
    qpSum: Array<number>;
    framesEncoded: Array<number>;
    keyFramesEncoded: Array<number>;
    frameWidth: Array<number>;
    frameHeight: Array<number>;
    frameBitDepth: Array<number>;
    framesPerSecond: Array<number>;
    framesSent: Array<number>;
    hugeFramesSent: Array<number>;
    totalPacketSendDelay: Array<number>;
    totalEncodeTime: Array<number>;
    totalEncodedBytesTarget: Array<number>;
    qualityLimitationResolutionChanges: Array<number>;

    constructor() {
        super();
        this.firCount = [];
        this.pliCount = [];
        this.nackCount = [];
        this.qpSum = [];
        this.framesEncoded = [];
        this.keyFramesEncoded = [];
        this.frameWidth = [];
        this.frameHeight = [];
        this.frameBitDepth = [];
        this.framesPerSecond = [];
        this.framesSent = [];
        this.hugeFramesSent = [];
        this.totalPacketSendDelay = [];
        this.totalEncodeTime = [];
        this.totalEncodedBytesTarget = [];
        this.qualityLimitationResolutionChanges = [];
    }

    push(outboundData: WebRTCOutboundVideoRtpStreamData) {
        super.push(outboundData);
        this.firCount.push(outboundData.firCount);
        this.pliCount.push(outboundData.pliCount);
        this.nackCount.push(outboundData.nackCount);
        this.qpSum.push(outboundData.qpSum);
        this.framesEncoded.push(outboundData.framesEncoded);
        this.keyFramesEncoded.push(outboundData.keyFramesEncoded);
        this.frameWidth.push(outboundData.frameWidth);
        this.frameHeight.push(outboundData.frameHeight);
        this.frameBitDepth.push(outboundData.frameBitDepth ? outboundData.frameBitDepth : 0);
        this.framesPerSecond.push(outboundData.framesPerSecond);
        this.framesSent.push(outboundData.framesSent);
        this.hugeFramesSent.push(outboundData.hugeFramesSent);
        this.totalPacketSendDelay.push(outboundData.totalPacketSendDelay);
        this.totalEncodeTime.push(outboundData.totalEncodeTime);
        this.totalEncodedBytesTarget.push(outboundData.totalEncodedBytesTarget);
        this.qualityLimitationResolutionChanges.push(
            outboundData.qualityLimitationResolutionChanges
        );
    }
}

export class WebRTCInboundVideoRtpStreamSeries extends TimestampSeries {
    packetsReceived: Array<number>;
    packetsLost: Array<number>;
    bytesReceived: Array<number>;
    firCount: Array<number>;
    frameHeight: Array<number>;
    frameWidth: Array<number>;
    framesDecoded: Array<number>;
    framesDropped: Array<number>;
    framesPerSecond: Array<number>;
    framesReceived: Array<number>;
    headerBytesReceived: Array<number>;
    keyFramesDecoded: Array<number>;
    nackCount: Array<number>;
    pliCount: Array<number>;
    totalDecodeTime: Array<number>;
    totalInterFrameDelay: Array<number>;
    totalSquaredInterFrameDelay: Array<number>;

    constructor() {
        super();
        this.packetsReceived = [];
        this.packetsLost = [];
        this.bytesReceived = [];
        this.firCount = [];
        this.frameHeight = [];
        this.frameWidth = [];
        this.framesDecoded = [];
        this.framesDropped = [];
        this.framesPerSecond = [];
        this.framesReceived = [];
        this.headerBytesReceived = [];
        this.keyFramesDecoded = [];
        this.nackCount = [];
        this.pliCount = [];
        this.totalDecodeTime = [];
        this.totalInterFrameDelay = [];
        this.totalSquaredInterFrameDelay = [];
    }

    push(inboundData: WebRTCInboundVideoRtpStreamData) {
        this.timestamps.push(inboundData.timestamp);
        this.packetsReceived.push(inboundData.packetsReceived);
        this.packetsLost.push(inboundData.packetsLost);
        this.bytesReceived.push(inboundData.bytesReceived);
        this.firCount.push(inboundData.firCount);
        this.framesPerSecond.push(inboundData.framesPerSecond);
        this.framesReceived.push(inboundData.framesReceived);
        this.headerBytesReceived.push(inboundData.headerBytesReceived);
        this.framesDecoded.push(inboundData.framesDecoded);
        this.keyFramesDecoded.push(inboundData.keyFramesDecoded);
        this.nackCount.push(inboundData.nackCount);
        this.pliCount.push(inboundData.pliCount);
        this.totalDecodeTime.push(inboundData.totalDecodeTime);
        this.totalInterFrameDelay.push(inboundData.totalInterFrameDelay);
        this.totalSquaredInterFrameDelay.push(inboundData.totalSquaredInterFrameDelay);
    }
}
export class WebRTCMediaStreamTrackData extends TimestampedData {
    frameWidth?: number;
    frameHeight?: number;
    framesSent?: number;
    hugeFramesSent?: number;

    constructor(stats: RTCOutboundMediaStreamTrackStats) {
        super(stats);
        this.frameWidth = stats.frameWidth;
        this.frameHeight = stats.frameHeight;
        this.framesSent = stats.framesSent;
        this.hugeFramesSent = stats.hugeFramesSent;
    }
}

export class WebRTCMediaStreamTrackSeries extends TimestampSeries {
    frameWidth: Array<number>;
    frameHeight: Array<number>;
    framesSent: Array<number>;
    hugeFramesSent: Array<number>;

    constructor() {
        super();
        this.frameWidth = [];
        this.frameHeight = [];
        this.framesSent = [];
        this.hugeFramesSent = [];
    }

    push(trackData: WebRTCMediaStreamTrackData) {
        this.timestamps.push(trackData.timestamp);
        this.frameWidth.push(trackData.frameWidth ? trackData.frameWidth : 0);
        this.frameHeight.push(trackData.frameHeight ? trackData.frameHeight : 0);
        this.framesSent.push(trackData.framesSent ? trackData.framesSent : 0);
        this.hugeFramesSent.push(trackData.hugeFramesSent ? trackData.hugeFramesSent : 0);
    }
}

export class WebRTCInboundAudioStreamTrackData extends TimestampedData {
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    audioLevel: number;
    concealedSamples: number;
    concealmentEvents: number;
    insertedSamplesForDeceleration: number;
    removedSamplesForAcceleration: number;
    silentConcealedSamples: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;
    totalSamplesReceived: number;
    jitterBufferFlushes: number; // missing in chrome

    constructor(stats: RTCInboundAudioStreamTrackStats) {
        super(stats);
        this.jitterBufferDelay = stats.jitterBufferDelay;
        this.jitterBufferEmittedCount = stats.jitterBufferEmittedCount;
        this.audioLevel = stats.audioLevel;
        this.concealedSamples = stats.concealedSamples;
        this.concealmentEvents = stats.concealmentEvents;
        this.insertedSamplesForDeceleration = stats.insertedSamplesForDeceleration;
        this.removedSamplesForAcceleration = stats.removedSamplesForAcceleration;
        this.silentConcealedSamples = stats.silentConcealedSamples;
        this.totalAudioEnergy = stats.totalAudioEnergy;
        this.totalSamplesDuration = stats.totalSamplesDuration;
        this.totalSamplesReceived = stats.totalSamplesReceived;
        this.jitterBufferFlushes = stats.jitterBufferFlushes;
    }
}

export class WebRTCInboundAudioStreamTrackSeries extends TimestampSeries {
    jitterBufferDelay: Array<number>;
    jitterBufferEmittedCount: Array<number>;
    audioLevel: Array<number>;
    concealedSamples: Array<number>;
    concealmentEvents: Array<number>;
    insertedSamplesForDeceleration: Array<number>;
    removedSamplesForAcceleration: Array<number>;
    silentConcealedSamples: Array<number>;
    totalAudioEnergy: Array<number>;
    totalSamplesDuration: Array<number>;
    totalSamplesReceived: Array<number>;
    jitterBufferFlushes: Array<number>;

    constructor() {
        super();
        this.jitterBufferDelay = [];
        this.jitterBufferEmittedCount = [];
        this.audioLevel = [];
        this.concealedSamples = [];
        this.concealmentEvents = [];
        this.insertedSamplesForDeceleration = [];
        this.removedSamplesForAcceleration = [];
        this.silentConcealedSamples = [];
        this.totalAudioEnergy = [];
        this.totalSamplesDuration = [];
        this.totalSamplesReceived = [];
        this.jitterBufferFlushes = [];
    }

    push(trackData: WebRTCInboundAudioStreamTrackData) {
        this.timestamps.push(trackData.timestamp);
        this.jitterBufferDelay.push(trackData.jitterBufferDelay);
        this.jitterBufferEmittedCount.push(trackData.jitterBufferEmittedCount);
        this.audioLevel.push(trackData.audioLevel);
        this.concealedSamples.push(trackData.concealedSamples);
        this.concealmentEvents.push(trackData.concealmentEvents);
        this.insertedSamplesForDeceleration.push(trackData.insertedSamplesForDeceleration);
        this.removedSamplesForAcceleration.push(trackData.removedSamplesForAcceleration);
        this.silentConcealedSamples.push(trackData.silentConcealedSamples);
        this.totalAudioEnergy.push(trackData.totalAudioEnergy);
        this.totalSamplesDuration.push(trackData.totalSamplesDuration);
        this.totalSamplesReceived.push(trackData.totalSamplesReceived);
        this.jitterBufferFlushes.push(trackData.jitterBufferFlushes);
    }
}

export class WebRTCInboundVideoStreamTrackData extends TimestampedData {
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    frameHeight: number;
    frameWidth: number;
    framesDecoded: number;
    framesDropped: number;
    framesReceived: number;
    freezeCount: number; // missing in chrome
    pauseCount: number; // missing in chrome
    totalFramesDuration: number; // missing in chrome
    totalFreezesDuration: number; // missing in chrome
    totalPausesDuration: number; // missing in chrome
    sumOfSquaredFramesDuration: number; // missing in chrome

    constructor(stats: RTCInboundVideoStreamTrackStats) {
        super(stats);
        this.jitterBufferDelay = stats.jitterBufferDelay;
        this.jitterBufferEmittedCount = stats.jitterBufferEmittedCount;
        this.frameHeight = stats.frameHeight;
        this.frameWidth = stats.frameWidth;
        this.framesDecoded = stats.framesDecoded;
        this.framesDropped = stats.framesDropped;
        this.framesReceived = stats.framesReceived;
        this.freezeCount = stats.freezeCount;
        this.pauseCount = stats.pauseCount;
        this.totalFramesDuration = stats.totalFramesDuration;
        this.totalFreezesDuration = stats.totalFreezesDuration;
        this.totalPausesDuration = stats.totalPausesDuration;
        this.sumOfSquaredFramesDuration = stats.sumOfSquaredFramesDuration;
    }
}

export class WebRTCInboundVideoStreamTrackSeries extends TimestampSeries {
    jitterBufferDelay: Array<number>;
    jitterBufferEmittedCount: Array<number>;
    frameHeight: Array<number>;
    frameWidth: Array<number>;
    framesDecoded: Array<number>;
    framesDropped: Array<number>;
    framesReceived: Array<number>;
    freezeCount: Array<number>;
    pauseCount: Array<number>;
    totalFramesDuration: Array<number>;
    totalFreezesDuration: Array<number>;
    totalPausesDuration: Array<number>;
    sumOfSquaredFramesDuration: Array<number>;

    constructor() {
        super();
        this.jitterBufferDelay = [];
        this.jitterBufferEmittedCount = [];
        this.frameHeight = [];
        this.frameWidth = [];
        this.framesDecoded = [];
        this.framesDropped = [];
        this.framesReceived = [];
        this.freezeCount = [];
        this.pauseCount = [];
        this.totalFramesDuration = [];
        this.totalFreezesDuration = [];
        this.totalPausesDuration = [];
        this.sumOfSquaredFramesDuration = [];
    }

    push(trackData: WebRTCInboundVideoStreamTrackData) {
        this.timestamps.push(trackData.timestamp);
        this.jitterBufferDelay.push(trackData.jitterBufferDelay);
        this.jitterBufferEmittedCount.push(trackData.jitterBufferEmittedCount);
        this.frameHeight.push(trackData.frameHeight);
        this.frameWidth.push(trackData.frameWidth);
        this.framesDecoded.push(trackData.framesDecoded);
        this.framesDropped.push(trackData.framesDropped);
        this.framesReceived.push(trackData.framesReceived);
        this.freezeCount.push(trackData.freezeCount);
        this.pauseCount.push(trackData.pauseCount);
        this.totalFramesDuration.push(trackData.totalFramesDuration);
        this.totalFreezesDuration.push(trackData.totalFreezesDuration);
        this.totalPausesDuration.push(trackData.totalPausesDuration);
        this.sumOfSquaredFramesDuration.push(trackData.sumOfSquaredFramesDuration);
    }
}

export class WebRTCAudioSourceData extends TimestampedData {
    audioLevel: number;
    totalAudioEnergy: number;
    totalSamplesDuration: number;

    constructor(stats: RTCAudioSourceStats) {
        super(stats);
        this.audioLevel = stats.audioLevel;
        this.totalAudioEnergy = stats.totalAudioEnergy;
        this.totalSamplesDuration = stats.totalSamplesDuration;
    }
}

export class WebRTCAudioSourceSeries extends TimestampSeries {
    audioLevel: Array<number>;
    totalAudioEnergy: Array<number>;
    totalSamplesDuration: Array<number>;
    echoReturnLoss?: Array<number>;
    echoReturnLossEnhancement?: Array<number>;

    constructor() {
        super();
        this.audioLevel = [];
        this.totalAudioEnergy = [];
        this.totalSamplesDuration = [];
    }

    push(sourceData: WebRTCAudioSourceData) {
        this.timestamps.push(sourceData.timestamp);
        this.audioLevel.push(sourceData.audioLevel);
        this.totalAudioEnergy.push(sourceData.totalAudioEnergy);
        this.totalSamplesDuration.push(sourceData.totalSamplesDuration);
    }
}

export class WebRTCVideoSourceData extends TimestampedData {
    framesPerSecond: number;

    constructor(stats: RTCVideoSourceStats) {
        super(stats);
        this.framesPerSecond = stats.framesPerSecond;
    }
}

export class WebRTCVideoSourceSeries extends TimestampSeries {
    framesPerSecond: Array<number>;

    constructor() {
        super();
        this.framesPerSecond = [];
    }

    push(sourceData: WebRTCVideoSourceData) {
        this.timestamps.push(sourceData.timestamp);
        this.framesPerSecond.push(sourceData.framesPerSecond);
    }
}

export class PublisherWebRTCMetricsUpdate {
    streamStats: RTCMediaStreamStats | null;
    videoSSRC: number;
    audioSSRC: number;
    videoStreamTrackData: WebRTCMediaStreamTrackData | null;
    audioSourceData: WebRTCAudioSourceData | null;
    videoSourceData: WebRTCVideoSourceData | null;
    audioOutboundRtpData: WebRTCOutboundAudioRtpStreamData | null;
    videoOutboundRtpData: WebRTCOutboundVideoRtpStreamData | null;
    transportData: WebRTCTransportData | null;

    constructor() {
        this.streamStats = null;
        this.videoSSRC = 0;
        this.audioSSRC = 0;
        this.videoStreamTrackData = null;
        this.audioSourceData = null;
        this.videoSourceData = null;
        this.audioOutboundRtpData = null;
        this.videoOutboundRtpData = null;
        this.transportData = null;
    }

    setAudioSSRC(ssrc: number) {
        this.audioSSRC = ssrc;
    }

    setVideoSSRC(ssrc: number) {
        this.videoSSRC = ssrc;
    }

    setAudioOutboundRtpStreamStats(stats: RTCOutboundRtpStreamStats) {
        this.audioOutboundRtpData = new WebRTCOutboundAudioRtpStreamData(stats);
    }

    setVideoOutboundRtpStreamStats(stats: RTCOutboundRtpStreamStats) {
        this.videoOutboundRtpData = new WebRTCOutboundVideoRtpStreamData(stats);
    }

    setTransportStats(stats: RTCTransportStats) {
        this.transportData = new WebRTCTransportData(stats);
    }

    setAudioSourceStats(stats: RTCAudioSourceStats) {
        this.audioSourceData = new WebRTCAudioSourceData(stats);
    }

    setVideoSourceStats(stats: RTCVideoSourceStats) {
        this.videoSourceData = new WebRTCVideoSourceData(stats);
    }

    setVideoTrackStats(stats: RTCOutboundMediaStreamTrackStats) {
        this.videoStreamTrackData = new WebRTCMediaStreamTrackData(stats);
    }

    setStreamStats(stats: RTCMediaStreamStats) {
        this.streamStats = stats;
    }
}

export class SubscriberWebRTCMetricsUpdate {
    streamStats: RTCMediaStreamStats | null;
    audioInboundRtpData: WebRTCInboundAudioRtpStreamData | null;
    videoInboundRtpData: WebRTCInboundVideoRtpStreamData | null;
    audioInboundTrackData: WebRTCInboundAudioStreamTrackData | null;
    videoInboundTrackData: WebRTCInboundVideoStreamTrackData | null;
    transportData: WebRTCTransportData | null;

    constructor() {
        this.streamStats = null;
        this.audioInboundRtpData = null;
        this.videoInboundRtpData = null;
        this.audioInboundTrackData = null;
        this.videoInboundTrackData = null;
        this.transportData = null;
    }

    setAudioInboundRtpStats(stats: RTCInboundRTPStreamStats) {
        this.audioInboundRtpData = new WebRTCInboundAudioRtpStreamData(stats);
    }

    setVideoInboundRtpStats(stats: RTCInboundRTPStreamStats) {
        this.videoInboundRtpData = new WebRTCInboundVideoRtpStreamData(stats);
    }

    setAudioInboundTrackStats(stats: RTCInboundAudioStreamTrackStats) {
        this.audioInboundTrackData = new WebRTCInboundAudioStreamTrackData(stats);
    }

    setVideoInboundTrackStats(stats: RTCInboundVideoStreamTrackStats) {
        this.videoInboundTrackData = new WebRTCInboundVideoStreamTrackData(stats);
    }

    setTransportStats(stats: RTCTransportStats) {
        this.transportData = new WebRTCTransportData(stats);
    }

    setStreamStats(stats: RTCMediaStreamStats) {
        this.streamStats = stats;
    }
}
