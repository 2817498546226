import axios, { AxiosError } from 'axios';
import {
    NotificationChannelType,
    NotificationType,
    User,
    UserNotificationOptOut,
    UserUsage,
} from './types';

export type RequestUserPUT = {
    name: string;
    email: string;
    didToken: string;
    provider?: string;
    accessToken?: string;
    scopes?: string[];
    phone?: string;
    handle?: string;
    openIDName?: string;
    picture?: string;
};

export type ResponseUserPUT = {
    accessToken: string;
    user: User;
};

export const userPUT = async (
    request: RequestUserPUT,
    origin: string
): Promise<ResponseUserPUT> => {
    const resp = await axios({
        method: 'PUT',
        url: `${origin}/user`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + request.didToken },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export const userGET = async (accessToken: string, origin: string): Promise<User> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/user`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + accessToken },
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export const userUsageGET = async (accessToken: string, origin: string): Promise<UserUsage> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/user/usage`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + accessToken },
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestUserPOST = {
    name: string;
    accessToken: string;
};

export const userPOST = async (request: RequestUserPOST, origin: string): Promise<User> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/user`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + request.accessToken },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export type RequestUserLoginPOST = {
    didToken: string;
    email: string;
    provider?: string;
    accessToken?: string;
    scopes?: string[];
    phone?: string;
    handle?: string;
    openIDName?: string;
    picture?: string;
};

export type ResponseUserLoginPOST = {
    accessToken: string;
    user: User;
};

export const userLoginPOST = async (
    request: RequestUserLoginPOST,
    origin: string
): Promise<ResponseUserLoginPOST> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/user/login`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + request.didToken },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export const userLogoutPOST = async (accessToken: string, origin: string): Promise<void> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/user/logout`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + accessToken },
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export const oneSignalHashGET = async (accessToken: string, origin: string): Promise<string> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/user/onesignal/hash`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + accessToken },
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export interface RequestOneSignalDeviceGET {
    playerID: string;
}

export const oneSignalDeviceGET = async (
    token: string,
    request: RequestOneSignalDeviceGET,
    origin: string
): Promise<boolean> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/user/onesignal/device`,
        responseType: 'json',
        params: request,
        headers: { Authorization: 'Bearer ' + token },
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export interface RequestOneSignalDevicePOST {
    playerID: string;
}

export const oneSignalDevicePOST = async (
    token: string,
    request: RequestOneSignalDevicePOST,
    origin: string
): Promise<void> => {
    await axios({
        method: 'POST',
        url: `${origin}/user/onesignal/device`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export interface RequestOneSignalDeviceDELETE {
    playerID: string;
}

export const oneSignalDeviceDELETE = async (
    token: string,
    request: RequestOneSignalDeviceDELETE,
    origin: string
): Promise<void> => {
    await axios({
        method: 'DELETE',
        url: `${origin}/user/onesignal/device`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export interface RequestUserNotificationsOptOutsGET {
    roomID: string;
    notification?: NotificationType;
    channel?: NotificationChannelType;
}

export const userNotificationsOptOutsGET = async (
    token: string,
    request: RequestUserNotificationsOptOutsGET,
    origin: string
): Promise<UserNotificationOptOut[]> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/user/notifications/optOut`,
        responseType: 'json',
        params: request,
        headers: { Authorization: 'Bearer ' + token },
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};

export interface RequestUserNotificationsOptOutPUT {
    roomID: string;
    notification: NotificationType;
    channel: NotificationChannelType;
}

export const userNotificationsOptOutPUT = async (
    token: string,
    request: RequestUserNotificationsOptOutPUT,
    origin: string
): Promise<UserNotificationOptOut> => {
    const resp = await axios({
        method: 'PUT',
        url: `${origin}/user/notifications/optOut`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });

    return resp.data;
};

export interface RequestUserNotificationsOptOutDELETE {
    id: string;
}

export const userNotificationsOptOutDELETE = async (
    token: string,
    request: RequestUserNotificationsOptOutDELETE,
    origin: string
): Promise<void> => {
    await axios({
        method: 'DELETE',
        url: `${origin}/user/notifications/optOut`,
        responseType: 'json',
        headers: { Authorization: 'Bearer ' + token },
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export const magicPubKeyGET = async (origin: string): Promise<string> => {
    const resp = await axios({
        method: 'GET',
        url: `${origin}/magic/pubKey`,
        responseType: 'text',
    }).catch((e: AxiosError) => {
        throw e;
    });

    return resp.data;
};

export type RequestUserAvatarPOST = {
    accessToken: string;
    avatar: Blob;
};

export const userAvatarPOST = async (
    request: RequestUserAvatarPOST,
    origin: string
): Promise<void> => {
    const formData = new FormData();
    formData.append('avatar', request.avatar);

    const resp = await axios({
        method: 'POST',
        url: `${origin}/user/avatar`,
        responseType: 'json',
        headers: {
            Authorization: 'Bearer ' + request.accessToken,
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export type RequestUserAvatarDELETE = {
    accessToken: string;
};

export const userAvatarDELETE = async (
    request: RequestUserAvatarDELETE,
    origin: string
): Promise<void> => {
    const resp = await axios({
        method: 'DELETE',
        url: `${origin}/user/avatar`,
        responseType: 'json',
        headers: {
            Authorization: 'Bearer ' + request.accessToken,
        },
    }).catch((e: AxiosError) => {
        throw e;
    });
};

export type RequestTestFlightLoginPOST = {
    email: string;
    password: string;
};

export type ResponseTestFlightLoginPOST = {
    accessToken: string;
    user: User;
};

export const testFlightLoginPOST = async (
    request: RequestTestFlightLoginPOST,
    origin: string
): Promise<ResponseTestFlightLoginPOST> => {
    const resp = await axios({
        method: 'POST',
        url: `${origin}/tfLogin`,
        responseType: 'json',
        data: request,
    }).catch((e: AxiosError) => {
        throw e;
    });
    return resp.data;
};
