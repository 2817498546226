import { useEffect, useRef } from 'react';

const useEffectOnce = (callback: () => void, args: any[]) => {
    const hasRun = useRef(false);

    useEffect(() => {
        if (!hasRun.current) {
            callback();
            hasRun.current = true;
        }
    }, args);
};

export default useEffectOnce;
