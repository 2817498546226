import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import TransitionVisible from '@internal/ui/legacy/components/TransitionVisible';

interface AppLoader {
    className?: string;
    visible: boolean;
    transitionDuration: number;
    transitionDelay: number;
}

const AppLoader: React.FunctionComponent<AppLoader> = (props) => {
    const { className, visible, transitionDuration, transitionDelay } = props;

    return (
        <TransitionVisible visible={visible} duration={transitionDelay + transitionDuration}>
            <AppLoaderStyled
                className={className}
                visible={visible}
                transitionDuration={transitionDuration}
                transitionDelay={transitionDelay}
            >
                <LogoContainerStyled>
                    <img src="/gif/logo-128-optimized.gif" height={64} width={64} />
                </LogoContainerStyled>
            </AppLoaderStyled>
        </TransitionVisible>
    );
};

const LogoContainerStyled = styled.div`
    height: ${rem(64)};
    width: ${rem(64)};
    border-radius: ${rem(12)};
    overflow: hidden;
    background-color: #bc2f38;
`;

const AppLoaderStyled = styled.div<{
    visible: boolean;
    transitionDuration: number;
    transitionDelay: number;
}>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ visible }) => (visible ? 1 : 0)};

    ${({ transitionDuration, transitionDelay }) => css`
        transition: opacity ${transitionDuration}ms ease-out ${transitionDelay}ms;
    `}
`;

export default AppLoader;
